import request from '../../service/request'

export const getHotProperties = data => {
  return request({
    method: 'get',
    url: 'get-hot-property'   //give url   
  })
    .then(response => response)
    .catch(({ data }) => ({ error: data }));
}

export const getPopularProjects = data => {
  var fd = new FormData();
  fd.append('json', data);
  return request({
    method: 'get',
    url: 'get-popular-projects',   //give url
    headers: { "Content-Type": "multipart/form-data" },
    data: fd
  })
    .then(response => response)
    .catch(({ data }) => ({ error: data }));
}

export const getAllProjects = data => {
  return request({
    method: 'post',
    url: 'get-all-projects', //give url   
    data: data
  })
    .then(response => response)
    .catch(({ data }) => ({ error: data }));
}

export const getOurServices = data => {
  var fd = new FormData();
  fd.append('json', data);
  return request({
    method: 'get',
    url: 'get-services',   //give url
    headers: { "Content-Type": "multipart/form-data" },
    data: fd
  })
    .then(response => response)
    .catch(({ data }) => ({ error: data }));
}

export const getOurAgents = data => {
  var fd = new FormData();
  fd.append('json', data);
  return request({
    method: 'get',
    url: 'get-agents',   //give url
    headers: { "Content-Type": "multipart/form-data" },
    data: fd
  })
    .then(response => response)
}

export const getTestimonials = data => {
  var fd = new FormData();
  fd.append('json', data);
  return request({
    method: 'get',
    url: 'get-testimonials',   //give url
    headers: { "Content-Type": "multipart/form-data" },
    data: fd
  })
    .then(response => response)
    .catch(({ data }) => ({ error: data }));
}

export const getOurPartners = data => {
  var fd = new FormData();
  fd.append('json', data);
  return request({
    method: 'get',
    url: 'get-partners',   //give url
    headers: { "Content-Type": "multipart/form-data" },
    data: fd
  })
    .then(response => response)
    .catch(({ data }) => ({ error: data }));
}

export const getPropertiesByFilter = data => {
  return request({
    method: 'post',
    url: 'get-properties-by-filter',
    // headers: { "Content-Type": "application/json", "Accept": "application/json" },
    data: data
  })
    .then(response => response)
    .catch(({ data }) => ({ error: data }));
}