import React, { useEffect, useState } from 'react'
import defaultIcon from "../../rels_assets/images/icons/defaultservices.jpg"
import { useDispatch, useSelector } from 'react-redux';
import { fetchOurServicesRequest } from '../Landing/actions';
import $, { data } from 'jquery'


const WhyChooseUs = () => {
    const dispatch = useDispatch();
    const [serviceData, setServiceData] = useState([]);
    const ourServicedata = useSelector(store => store.landingPageData.ourServices.length > 0 ? store.landingPageData.ourServices : store.landingPageData.ourServices)
    useEffect(() => {
        loadData();
    }, []);
    useEffect(() => {
        if (typeof ourServicedata.data === 'object' && Object.keys(ourServicedata.data).length > 0) {
            setServiceData(ourServicedata.data);
        }
    }, [ourServicedata]);

    /** Custom function */
    function loadData() {
        dispatch(fetchOurServicesRequest(JSON.stringify([data])))
    }
    return (
        <>
            {
                serviceData && serviceData.length > 0 ?
                    <>
                        {/* <!-- START SECTION WHY CHOOSE US --> */}
                        <section className="how-it-works bg-white rec-pro">
                            <div className="container-fluid aboutus-container-fluid">
                                <div className="sec-title">
                                    <h2><span>Why </span>Choose Us</h2>
                                    {/* <p>We provide full service at every step.</p> */}
                                </div>
                                <div className="row service-1">
                                    {serviceData.map((item) => (
                                        <article key={`whychooseus-sevicedata-${item.id}`} className="col-lg-3 col-md-6 col-xs-12 serv my-2" data-aos="fade-up" data-aos-delay="150">
                                            <div className="serv-flex">
                                                <div className="art-1 img-13">
                                                    <img loading='lazy' src={item.banner_image ? item.banner_image : defaultIcon} alt="" />
                                                    <h3>{item.name}</h3>
                                                </div>
                                                <div className="service-text-p">
                                                    <p className="text-center" dangerouslySetInnerHTML={{ __html: item.service_description }}>
                                                    </p>
                                                </div>
                                            </div>
                                        </article>
                                    ))}
                                </div>
                            </div>
                        </section>
                        {/* <!-- END SECTION WHY CHOOSE US -->          */}
                    </> : ""
            }
        </>
    )
}

export default WhyChooseUs
