import * as actionTypes from './constants'

function InquiryReducer(state = {}, action) {
    const { type, payload } = action;
    switch (type) {

        case actionTypes.TOGGELE_INQUIRY_POPUP:
            return {
                ...state,
                show: payload.show,
                product: payload.product
            }
        case actionTypes.LIST_INQUIRY_PRODUCT_REQUEST:
            return {
                ...state,
                isInquiryDataLoading: true,
            };
        case actionTypes.LIST_INQUIRY_PRODUCT_SUCCESS:
            return {
                ...state,
                isInquiryDataLoading: false,
                inquiryData: payload.data,
                inquiryPageMeta:payload.meta
            };
        case actionTypes.LIST_INQUIRY_PRODUCT_FAILURE:
            return {
                ...state,
                isInquiryDataLoading: false,
            };
        default:
            return state
    }

}

export default InquiryReducer;