export const GET_LANDING_DATA_REQUEST = 'GET_LANDING_DATA_REQUEST';
export const GET_LANDING_DATA_SUCCESS = 'GET_LANDING_DATA_SUCCESS';
export const GET_LANDING_DATA_FAILURE = 'GET_LANDING_DATA_FAILURE';

// Hot Properties
export const GET_HOT_PROPERTIES_REQUEST = 'GET_HOT_PROPERTIES_REQUEST';
export const GET_HOT_PROPERTIES_SUCCESS = 'GET_HOT_PROPERTIES_SUCCESS';
export const GET_HOT_PROPERTIES_FAILURE = 'GET_HOT_PROPERTIES_FAILURE';

// Popular projects
export const GET_POPULAR_PROJECTS_REQUEST = 'GET_POPULAR_PROJECTS_REQUEST';
export const GET_POPULAR_PROJECTS_SUCCESS = 'GET_POPULAR_PROJECTS_SUCCESS';
export const GET_POPULAR_PROJECTS_FAILURE = 'GET_POPULAR_PROJECTS_FAILURE';

// Popular projects
export const GET_ALL_PROJECTS_REQUEST = 'GET_ALL_PROJECTS_REQUEST';
export const GET_ALL_PROJECTS_SUCCESS = 'GET_ALL_PROJECTS_SUCCESS';
export const GET_ALL_PROJECTS_FAILURE = 'GET_ALL_PROJECTS_FAILURE';

// Customers
export const GET_ALL_CUSTOMERS_REQUEST = 'GET_ALL_CUSTOMERS_REQUEST';
export const GET_ALL_CUSTOMERS_SUCCESS = 'GET_ALL_CUSTOMERS_SUCCESS';
export const GET_ALL_CUSTOMERS_FAILURE = 'GET_ALL_CUSTOMERS_FAILURE';

// Our services
export const GET_OUR_SERVICES_REQUEST = 'GET_OUR_SERVICES_REQUEST';
export const GET_OUR_SERVICES_SUCCESS = 'GET_OUR_SERVICES_SUCCESS';
export const GET_OUR_SERVICES_FAILURE = 'GET_OUR_SERVICES_FAILURE';

// Our agents
export const GET_OUR_AGENTS_REQUEST = 'GET_OUR_AGENTS_REQUEST';
export const GET_OUR_AGENTS_SUCCESS = 'GET_OUR_AGENTS_SUCCESS';
export const GET_OUR_AGENTS_FAILURE = 'GET_OUR_AGENTS_FAILURE';

// Testimonials
export const GET_TESTIMONIALS_REQUEST = 'GET_TESTIMONIALS_REQUEST';
export const GET_TESTIMONIALS_SUCCESS = 'GET_TESTIMONIALS_SUCCESS';
export const GET_TESTIMONIALS_FAILURE = 'GET_TESTIMONIALS_FAILURE';

// Our Partners
export const GET_OUR_PARTNERS_REQUEST = 'GET_OUR_PARTNERS_REQUEST';
export const GET_OUR_PARTNERS_SUCCESS = 'GET_OUR_PARTNERS_SUCCESS';
export const GET_OUR_PARTNERS_FAILURE = 'GET_OUR_PARTNERS_FAILURE';

export const SET_SEARCH_OPTIONS_REQUEST = 'SET_SEARCH_OPTIONS_REQUEST';

export const SET_SELECTED_OPTIONS_REQUEST = 'SET_SELECTED_OPTIONS_REQUEST';

export const GET_PROPERTIES_BY_FILTER_REQUEST = 'GET_PROPERTIES_BY_FILTER_REQUEST';
export const GET_PROPERTIES_BY_FILTER_SUCCESS = 'GET_PROPERTIES_BY_FILTER_SUCCESS';
export const GET_PROPERTIES_BY__FILTER_FAILURE = 'GET_PROPERTIES_BY__FILTER_FAILURE';