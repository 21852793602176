import request from '../../service/request'

export const getMenu = data => {
    var fd = new FormData();
    fd.append('json', data);
    return request({
      method: 'get',
      url: 'get-menu',   //give url    
      data: fd
    })
      .then(response => response)
      .catch(({ data }) => ({ error: data }));
  }

export const getSearchOptions = data => {
  var fd = new FormData();
  fd.append('json', data);
  return request({
    method: 'get',
    url: 'get-filter-options',   //give url    
    data: fd
  })
    .then(response => response)
    .catch(({ data }) => ({ error: data }));
}