
import { call, put } from 'redux-saga/effects';
import * as actionTypes from './actions'
import { getMenu,getSearchOptions } from './commonService';
export function* fetchMenu(action) {
    const { payload } = action;
    const { data } = yield call(getMenu, payload);
    if (data) {
      yield put(actionTypes.fetchMenuSuccess(data));
    } else {
      yield put(actionTypes.fetchMenuFailure());
    }
  }

export function* fetchSearchOptions(action){
  const {payload} = action;
  const {data} = yield call(getSearchOptions,payload);
  if (data) {
    yield put(actionTypes.fetchSearchOptionsSuccess(data));
  } else {
    yield put(actionTypes.fetchSearchOptionsFailure());
  }

}