import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
// import './pagination.css';
const NewPagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });
  
  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || (paginationRange && paginationRange.length < 2) ) {
    return null;
  }
  let lastPage = paginationRange[paginationRange.length - 1];
  const onNext = () => {
    if(currentPage < lastPage) {
      onPageChange(currentPage + 1);
    }    
  };

  const onPrevious = () => {
    if(currentPage > 1) {
      onPageChange(currentPage - 1);  
    }    
  };  
  
  return (
    <nav aria-label="..." className="pt-3">
    <ul
      className={classnames('pagination lis-view', { [className]: className })}
    >
       {/* Left navigation arrow */}
      <li
        className={classnames('page-item', {
          disabled: currentPage === 1
        })}
        onClick={onPrevious}
      >
        {/* <div className="arrow left" /> */}
        <a className="page-link" href="#" tabindex="-1">Previous</a>        
      </li>

      {paginationRange.map((pageNumber,index) => {

        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return <li key={`pagiantion-${index}`} className="page-item dots"><a className="page-link" href="#">&#8230;</a> </li>;
        }

        // Render our Page Pills
        return (
          <li
            key={`pagiantion-${index}`}
            className={classnames('page-item', {
              active: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            <a className="page-link" href="javascript:void(0);" >{pageNumber}</a>            
          </li>
        );
      })}
      {/*  Right Navigation arrow */}
      <li
        className={classnames('page-item', {
          disabled: currentPage === lastPage
        })}
        onClick={onNext}
      >
        
        <a className="page-link" href="#">Next</a>
        {/* <i  className='pe-7s-angle-right'></i> */}
      </li>
    </ul>
    </nav>
  );
};

export default NewPagination;
