import React, { useEffect, useState } from 'react'
import img from "../../rels_assets/images/team/t-5.jpg"
import { useDispatch, useSelector } from 'react-redux';
import { fetchOurAgentsRequest } from '../Landing/actions';
import $, { data } from 'jquery'
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const MeetOurAgent = () => {
    const dispatch = useDispatch();
    const [agents, setAgents] = useState([]);
    const ourAgentsData = useSelector(store => store.landingPageData.ourAgents.length > 0 ? store.landingPageData.ourAgents : store.landingPageData.ourAgents)

    const options = {
        items: 6,
        nav: true,
        dots: false,
        rewind: true,
        autoplay: false,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
            },
            574: {
                items: 1,
            },
            768: {
                items: 3,
            },
            991: {
                items: 3,
            },
            1200: {
                items: 6,
            },
            1920: {
                items: 6,
            },
        },
        lazyLoad: true,

    }

    useEffect(() => {
        loadData();
    }, []);
    useEffect(() => {
        if (typeof ourAgentsData.data === 'object' && Object.keys(ourAgentsData.data).length > 0) {
            setAgents(ourAgentsData.data);
        }
    }, [ourAgentsData]);

    function loadData() {
        dispatch(fetchOurAgentsRequest(JSON.stringify([data])));
    }

    return (
        <>

            {/* <!-- START SECTION AGENTS --> */}
            {agents.length > 0 &&
                <section className="team bg-white rec-pro">
                    <div className="container-fluid aboutus-container-fluid">
                        <div className="sec-title">
                            <h2><span>Meet Our </span>Agents</h2>
                            {/* <p>Our Agents are here to help you</p> */}
                        </div>
                        <OwlCarousel {...options} className='row team-all owl-theme job_meetagentSlide '>
                            {/* <div className="team-all"> */}
                            {agents.map((item) => (
                                <div className="team-block col-12 " key={item.id} data-aos="fade-up" data-aos-delay="150">
                                    <div className="inner-box team-details">
                                        <div className="image team-head">
                                            <a href="" aria-label={`agent-img-${item.id}`}>
                                                <img loading='lazy' src={(item.logo) ? item.logo : img} height={640} width={500} alt="agent" onError={(e) => (
                                                    e.target.onerror = null,
                                                    e.target.src = img
                                                )} /></a>
                                            <div className="team-hover">
                                                {/* <ul className="team-social">
                                                <li><a href="#" aria-label="facebook" className="facebook"><i className="fa fa-facebook"></i></a></li>
                                                <li><a href="#" aria-label="twitter" className="twitter"><i className="fa fa-twitter"></i></a></li>
                                                <li><a href="#" aria-label="instagram" className="instagram"><i className="fa fa-instagram"></i></a></li>
                                                <li><a href="#" aria-label="linkedin" className="linkedin"><i className="fa fa-linkedin"></i></a></li>
                                            </ul> */}
                                            </div>
                                        </div>
                                        <div className="lower-box">
                                            <h3><a href="#" aria-label={`${item.name}`}>{item.name}</a></h3>
                                            <div className="designation">{item.designation}</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {/* </div> */}
                        </OwlCarousel>
                    </div>
                </section>
            }
            {/* <!-- END SECTION AGENTS --> */}
        </>
    )
}

export default MeetOurAgent
