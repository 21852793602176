export const HOME_PATH = '/';
export const PROPERTY_LIST = '/properties-list';
export const PROPERTY_DETAILS = '/property-details';
/* export const ABOUT_US_PATH = '/about-us';
export const CONTACT_US_PATH = '/contact-us';
export const FAQ_PATH = '/faq';
export const TERMS_CONDITION_PATH = '/terms-condition';
export const PRIVACY_POLICY = '/privacy-policy';
export const PRODUCT_DETAILS = '/product-details';
export const MY_CART = '/my-cart';
export const CATEGORIES_PATH = '/categories';
export const CHECK_OUT = '/checkout';
export const MY_PROFILE = '/my-profile';
export const MY_ORDER = "/my-order";
export const SAVED_ADDRESS ='/my-address';
export const MY_ACCOUNT ='/my-account';
export const MY_WISHLIST ='/my-wishlist';
export const MY_INQUIRY ='/my-inquiry';
export const ORDER_DETAILS = '/vieworderdItems'; */
