import React, { useEffect, useRef, useState } from 'react';
import request from '../../service/request';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { SYS_MESSAGE_TYPE_SUCCESS } from '../notifications/notificationTypes';
import { useDispatch } from 'react-redux';
import { addSysMessage } from '../notifications/system/sysMessageAction';
import { Link, useLocation } from 'react-router-dom';
import _ from "lodash";
import { Helmet } from 'react-helmet';

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

const ContactUs = (props) => {
    const dispatch = useDispatch();
    const customLocation = useLocation();
    const [pageData, setPageData] = useState([]);
    const [pageNotExist, setPageNotExist] = useState(false);
    const [subscriptionMsg, setSubscriptionMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const pageslug = 'contact-us';
    const formikRef = useRef();
    const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
    const validation = yup.object().shape({
        name: yup.string().min(2).max(30, "maximum 30 character allowed").required("Name is required."),
        email: yup.string().email().required("Email is required."),
        phone: yup.string().optional().matches(phoneRegExp, 'Phone number is not valid'),
        message: yup.string().required("Message is required.").min(10).max(100),
    });

    const initialValues = {
        name: '',
        phone: '',
        email: '',
        message: '',
    };

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid
    } = useFormik({
        initialValues: initialValues,
        validationSchema: validation,
        onSubmit: (values, actions) => {
            setIsLoading(true);
            request({
                method: 'post',
                url: '/contactus/save-inquiry',
                headers: { "Content-Type": "application/json", "Accept": "application/json" },
                data: values
            })
                .then((response) => {
                    setSubscriptionMsg(response?.data?.message);
                    /* dispatch(addSysMessage(response?.data?.message, SYS_MESSAGE_TYPE_SUCCESS)); */
                    setIsLoading(false);
                    actions.resetForm();
                })
                .catch(({ data }) => {
                    Object.keys(data.errors).map((index, item) => {
                        actions.setFieldError(index, _.get(data.errors, `${index}[0]`));
                    });
                    setIsLoading(false);
                });
        },
    });

    const [menu, setMenu] = useState([]);
    const [settings, setSetting] = useState([]);
    const [bannerImage, setBannerImage] = useState("");

    const getPageData = () => {
        request({
            method: 'post',
            url: 'pages/get-page-by-slug',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
            data: { 'slug': pageslug }
        })
            .then((response) => {
                setPageData(response.data);
            })
            .catch(({ data }) => {
                setPageNotExist(true);
            });
    };
    useEffect(() => {
        scrollToTop();
        request({
            method: 'get',
            url: '/get-menu',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
        })
            .then((response) => {
                setMenu(response?.data);
            })
            .catch(({ data }) => ({ error: data }));
        getPageData();
    }, []);

    useEffect(() => {
        if (menu && menu.code === 0) {
            setSetting(menu.data.site_setting);
        }
    }, [menu]);

    useEffect(() => {
        setBannerImage((pageData?.data?.banner_image) ? pageData?.data?.banner_image : 'https://placehold.co/1920x1280');
    }, [pageData]);

    return (
        <>
            <div>
                <Helmet
                    bodyAttributes={{
                        class: ((customLocation.pathname === "/")) ? 'homepage-9 hp-6 homepage-11 mh' : 'inner-pages homepage-4 homepage-1 agents hp-6 full hd-white about'
                    }}>
                    <title>{(pageData && pageData?.data && Object.keys(pageData?.data).length > 0) ? `${menu.data?.site_setting?.site_name} - ${pageData?.data.meta_title}` : "Contact Us"}</title>
                    <meta name="description" content={pageData && pageData?.data && Object.keys(pageData?.data).length > 0 && pageData?.data.meta_description} />
                    {pageData?.data &&
                        <script type="application/ld+json">
                            {JSON.stringify({
                                "@context": "https://schema.org/",
                                "@type": "Website",
                                "name": pageData?.data.meta_title,
                                "description": pageData?.data.meta_description,
                                "image": `${bannerImage}`,
                            })}
                        </script>
                    }

                    {/*  Open Graph / Facebook  */}
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://realestate.techcelerity.com/react/page/contact-us" />
                    <meta property="og:title" content={`Real Estate - ${(pageData && pageData?.data && Object.keys(pageData?.data).length > 0) ? pageData?.data.meta_title : "Contact Us"}`} />
                    <meta property="og:description" content={pageData && pageData?.data && Object.keys(pageData?.data).length > 0 && pageData?.data.meta_description} />
                    <meta property="og:image" content={bannerImage} />

                    {/*  Twitter */}
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://realestate.techcelerity.com/react/page/contact-us" />
                    <meta property="twitter:title" content={`Real Estate - ${(pageData && pageData?.data && Object.keys(pageData?.data).length > 0) ? pageData?.data.meta_title : "Contact Us"}`} />
                    <meta property="twitter:description" content={pageData && pageData?.data && Object.keys(pageData?.data).length > 0 && pageData?.data.meta_description} />
                    <meta property="twitter:image" content={bannerImage} />
                </Helmet>
            </div>

            <main id="main">
                <section className='headings' style={{ background: `transparent` }}>
                    <div className='text-heading text-right'>
                        <div className='container'>
                            <h1 style={{
                                backgroundImage: `url('${bannerImage}')`,
                                backgroundImage: `-webkit-image-set(url('${bannerImage}') 1x, url('${bannerImage}') 2x)`,
                                backgroundImage: `image-set(url('${bannerImage}') 1x, url('${bannerImage}') 2x)`,
                                backgroundSize: "contain, cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "left center",
                                height: "35vh",
                                padding: "7rem 0",
                                margin: "2rem 0",
                            }}>CONTACT US {/* <div className='h6'><Link to={`/`}>Home</Link> /  Contact Us</div> */}</h1>
                            <h2>
                                {/* &nbsp;/&nbsp; */}
                            </h2>
                        </div>
                    </div>
                </section>

                <section className='contact-us'>
                    <div className='container'>
                        {pageData?.data?.page_description &&
                            <div className="property-location mb-5">
                                <h3>Our Location</h3>
                                <div className="divider-fade"></div>
                                {
                                    <div id="map-contact" className="contact-map" dangerouslySetInnerHTML={{ __html: pageData?.data?.page_description }}>
                                    </div>
                                }
                            </div>
                        }
                        <div className='row'>
                            <div className='col-lg-8 col-md-12'>
                                <h3 className='mb-4'>Contact Us</h3>
                                <form id='contactform' className='contact-form' name='contactform' method='post' onSubmit={handleSubmit} noValidate='novalidate'>
                                    <div id='success' className='successform'>
                                        <p className='alert alert-success font-weight-bold' role='alert'>Your Message Was Sent Successfully!</p>
                                    </div>
                                    <div id='error' className='errorform'>
                                        <p>Something went wrong, try refreshing and submitting the form again.</p>
                                    </div>
                                    <div className='form-group'>
                                        <input type="text" id={values.id} name="name" placeholder="Enter your Full Name" value={values.name} onChange={handleChange} onBlur={handleBlur} className="form-control1 input-custom input-full" />
                                        {errors.name && touched.name ? (<div className="text-danger ">{errors.name}</div>) : " "}
                                    </div>
                                    <div className='form-group'>
                                        <input type="text" id={values.id} value={values.phone} onChange={handleChange} onBlur={handleBlur} className="form-control input-custom input-full" name="phone" placeholder="Mobile Number" aria-required="true" />
                                        {errors.phone && touched.phone ? (<div className="text-danger ">{errors.phone}</div>) : " "}
                                    </div>
                                    <div className='form-group'>
                                        <input type="text" id={values.id} value={values.email} onChange={handleChange} onBlur={handleBlur}
                                            aria-required="true" className="form-control input-custom input-full" name="email" placeholder="Email" />
                                        {errors.email && touched.email ? (<div className="text-danger ">{errors.email}</div>) : " "}
                                    </div>
                                    <div className='form-group'>
                                        <textarea id={values.id} value={values.message} onChange={handleChange} onBlur={handleBlur} className="form-control textarea-custom input-full" name="message" rows="8" placeholder="Message" aria-required="true"></textarea>
                                        {errors.message && touched.message ? (<div className="text-danger ">{errors.message}</div>) : " "}
                                    </div>
                                    <button type="submit" id="submit-contact" className="btn btn-primary btn-lg" onClick={handleSubmit} disabled={(isLoading || !isValid)}>
                                        {isLoading ? (<Loader />) : ''} Submit
                                    </button>
                                    <p className="subscription-success text-dark mt-3 h3">{subscriptionMsg}</p>
                                </form>
                            </div>
                            <div className='col-lg-4 col-md-12 bgc'>
                                <div className='call-info'>
                                    <h3>Contact Details</h3>
                                    <p className="mb-5">Please find below contact details and contact us today!</p>
                                    <ul>
                                        {settings && settings.address &&
                                            <li>
                                                <div className="info">
                                                    <i
                                                        className="fa fa-map-marker"
                                                        aria-hidden="true"
                                                    ></i>
                                                    <p className="in-p">
                                                        {settings.address.length && settings.address} {settings.city.length && settings.city} - {settings.zipcode.length && settings.zipcode}
                                                    </p>
                                                </div>
                                            </li>
                                        }
                                        {settings && settings.mobile &&
                                            <li>
                                                <div className="info">
                                                    <i
                                                        className="fa fa-phone"
                                                        aria-hidden="true"
                                                    ></i>
                                                    <p className="in-p">
                                                        {settings.mobile.length && settings.mobile}
                                                    </p>
                                                </div>
                                            </li>
                                        }
                                        {settings && settings.email &&
                                            <li>
                                                <div className="info">
                                                    <i
                                                        className="fa fa-envelope"
                                                        aria-hidden="true"
                                                    ></i>
                                                    <p className="in-p ti">
                                                        {settings.email.length && settings.email}
                                                    </p>
                                                </div>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

function Loader() {
    return (
        <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    );
}

export default ContactUs;
