import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedOptionsRequest } from '../Landing/actions';
import Select from 'react-select'
import { setMeasurementLabelRequest } from './actions';
import request from '../../service/request';

const DropDownData = (props) => {
    const dispatch = useDispatch();
    const [togglePropertyType, setTogglePropertyType] = useState(false);
    const [choosedOption, setChoosedOption] = useState('');
    const [myoptions, setMyOptions] = useState([]);
    const [measurementname, setMeasurementType] = useState("");
    const [city, setCity] = useState([]);
    let storeKey = props.storeKey;
    let defaultOption = props.defaultOption;
    const propertyTypeOption = useSelector(store => store.landingPageData.selectedOptions.property_type);
    const stateID = useSelector(store => store.landingPageData.selectedOptions.state);
    const newArr = [];
    useEffect(() => {
        let arr = [];
        if (props && props.choosedOption) {
            setChoosedOption(props.choosedOption)
        }
        props.options && props.options.length > 0 && props.options.map((types) => {
            const numberToBeAdded = { value: types.id, label: types.name };
            arr = [...arr, { ...numberToBeAdded }];
        })
        setMyOptions(arr);
    }, [props]);   

    useEffect(() => {
        if (props.clearDropdown) {
            setChoosedOption(''); // Reset the selected option
            dispatch(setSelectedOptionsRequest({ [storeKey]: '' })); // Clear the selected option in Redux store
        }
        if (storeKey == "measurement") {
            dispatch(setMeasurementLabelRequest());
        }
    }, [props.clearDropdown, props.clearKey]);

    const handleOnBlur = () => {
        setTogglePropertyType(false);
    }

    return (
        <>
            {/* <div key={props.choosedOption} className={`rld-single-select ml-22 ${props.className ? props.className : 'col-lg-3 col-md-5'} `} onBlur={() => { setTogglePropertyType(false) }}>
                <div className={`nice-select select single-select w-100  ${togglePropertyType ? 'open' : ''}`} onClick={() => { setTogglePropertyType(!togglePropertyType) }} tabIndex="0">
                    <span className="current">{choosedOption}</span>
                    <ul className="list">
                        <li className="option" onClick={(e) =>{ e.preventDefault(); setChoosedOption(props.choosedOption)}}>{props.choosedOption}</li>
                        {
                            props.options && props.options.length > 0 && props.options.map((types) => {
                                return <li className="option" onClick={(e) => { e.preventDefault(); setChoosedOption((prev) => types.name); dispatch(setSelectedOptionsRequest({[storeKey]:types.id})); }} >{types.name}</li>
                            })
                        }
                    </ul>
                </div>
            </div> */}

            <div key={props.choosedOption} className={`rld-single-select ml-22 ${props.className ? props.className : 'col-lg-4 col-md-5'} `} onBlur={handleOnBlur}>
            <label className='align-self-start d-flex text-muted font-weight-bold'>{props.choosedOption}</label>

                <Select className="text-left" maxMenuHeight={220} value={(props.defaultOption) ? ((myoptions.find(op => { return op.value == props.defaultOption }))) : null} key={props.choosedOption}  options={myoptions} isSearchable={true} isClearable={true}
                    onChange={(newValue) => {
                        const optVal = (newValue?.value == undefined) ? "" : newValue?.value;
                        setChoosedOption(optVal);
                        if (storeKey == "property_type" && optVal && optVal != propertyTypeOption) {
                            dispatch(setSelectedOptionsRequest({ 'specific_property': '', 'property_configuration': '' }));
                        }
                        if(storeKey == "state" && optVal && optVal != stateID){
                            dispatch(setSelectedOptionsRequest({'city': ''}));
                        }
                        if (storeKey == "measurement") {
                            dispatch(setMeasurementLabelRequest(newValue?.label));
                        }
                        dispatch(setSelectedOptionsRequest({ [storeKey]: optVal }));
                    }}
                    styles={{
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            color: state.isFocused ? 'white' : '',
                            
                        }),
                    }} theme={(theme) => ({
                        ...theme,
                        borderRadius: 1,
                        colors: {
                            ...theme.colors,
                            primary25: process.env.REACT_APP_THEME_COLOR ? process.env.REACT_APP_THEME_COLOR : '#FF385C',
                            primary: '#999',
                            primary50: '#999',
                        },
                        spacing: {
                            baseUnit: 5,
                        }
                    })} />

            </div>
        </>
    )
}

export default DropDownData;
