import _ from "lodash"

let landingPageData = {
    hotPropertyData: [],
    isLandingDataLoading: false,
    popularProjects: [],
    popularProjectsLoading:false,
    allProjects:[],
    allProjectsLoading:false,
    ourServices: [],
    isOurServiceDataLoading: false,
    ourAgents: [],
    isOurAgentsLoading: false,
    testimonials: [],
    isTestimonialsLoading: false,
    partners: [],
    isPartnersLoading: false,    
    selectedOptions:{
        hot_property:'',
        property_type: '',
        property_for: '',
        specific_property: '',
        measurement:'',
        area_size:"",
        area:'',
        country:101,
        state:'',
        city:'',
        page:1,
        sort_by:'latest',
        property_configuration:''},
    isPropertyListLoading: false,
    propertiesByFilter: []

}
let commonPageData = {
    menu: [],
    isMenuLoading: false,
    toggleMenu:false,
    isSeachOptionsLoading: false,
    searchOptions: {},
    measurement_lable:""
}

let initialData = {
    landingPageData,
    commonPageData
}

export default initialData