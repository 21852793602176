import { keys } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import request from '../../service/request';
import PageNotFound from '../common/pagenotfound';

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

function Page(props) {
    const dispatch = useDispatch();
    const customLocation = useLocation();
    const history = useHistory();
    const [menu, setMenu] = useState([]);
    const [pageData, setPageData] = useState([]);
    const [productNotExist, setProductNotExist] = useState(false);
    const [bannerImage, setBannerImage] = useState("");

    let { id } = useParams();
    const pageslug = id;

    useEffect(() => {
        getPageData();
        scrollToTop();
    }, [])

    useEffect(() => {
        getPageData()

    }, [props.match.params]);

    const getPageData = () => {
        request({
            method: 'post',
            url: 'pages/get-page-by-slug',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
            data: { 'slug': pageslug }
        })
            .then((response) => {
                setPageData(response.data);
                /*  let b = response.data.brands;
                 b = b.map((item)=>{
                     return {...item,selected:false}
                 })
                 setBrandList(b); */
            })
            // .catch(({ data }) => ({ error: data }))
            .catch(({ data }) => {
                setProductNotExist(true);
            });
    }

    useEffect(() => {
        request({
            method: 'get',
            url: '/get-menu',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
        })
            .then((response) => {
                setMenu(response?.data);
            })
            .catch(({ data }) => ({ error: data }));
        getPageData();
    }, []);

    useEffect(() => {
        setBannerImage((pageData?.data?.banner_image) ? pageData?.data?.banner_image : '')
    }, [pageData]);
    return (
        <>
            {productNotExist ? <PageNotFound /> :
                <>
                    <div>
                        <HelmetProvider>
                            <Helmet bodyAttributes={{
                                class: ((customLocation.pathname == "/")) ? 'homepage-9 hp-6 homepage-11 mh' : 'inner-pages homepage-4 homepage-1 agents hp-6 full hd-white about'
                            }}>
                                <title>{(pageData && pageData?.data && Object.keys(pageData?.data).length > 0) ? `${menu.data?.site_setting?.site_name} - ${pageData?.data.meta_title}` : ""}</title>
                                <meta name="description" content={pageData && pageData?.data && Object.keys(pageData?.data).length > 0 && pageData?.data.meta_description} />
                                {
                                    pageData?.data &&
                                    <script type="application/ld+json">
                                        {JSON.stringify({
                                            "@context": "https://schema.org/",
                                            "@type": "Website",
                                            "name": pageData?.data.meta_title,
                                            "description": pageData?.data.meta_description,
                                        })}
                                    </script>
                                }
                            </Helmet>
                        </HelmetProvider>
                    </div>
                    <main id="main">
                        <section className='headings-vendor' style={{ background: `transparent` }}> {/* style={{ background: `url(https://placehold.co/1920x1280)` }} */}
                            <div className='text-heading'>
                                <div className='container'>
                                    <h1 className='text-right' style={{
                                        backgroundImage: `url('${bannerImage}')`,
                                        backgroundImage: `-webkit-image-set(url('${bannerImage}') 1x, url('${bannerImage}') 2x)`,
                                        backgroundImage: `image-set(url('${bannerImage}') 1x, url('${bannerImage}') 2x)`,
                                        backgroundSize: "contain, cover",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "left center", height: "35vh", padding: "7rem 0", margin: "2rem 0",
                                        fontSize: "26px",
                                    }}>
                                        {pageData && pageData?.data && pageData && pageData?.data.name}
                                    </h1>
                                </div>
                            </div>
                        </section>
                        {/* START SECTION ABOUT */}
                        {pageData?.data?.page_description &&
                            <section className='about-us fh py-0'>
                                <div className='container' dangerouslySetInnerHTML={{ __html: (pageData?.data?.page_description) ? pageData?.data?.page_description : "Description not available!" }} ></div>
                            </section>
                        }

                        {/* END SECTION ABOUT */}


                    </main>

                </>
            }
        </>
    )
}
export default Page;
