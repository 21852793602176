import React, { useEffect, useState } from 'react'
import image from "../../rels_assets/images/blog/b-11.jpg";
import userimage from "../../rels_assets/images/testimonials/ts-2.jpg"
import SearchFilters from '../common/Hero/SearchFilters';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPropertiesByFilterRequest, setSelectedOptionsRequest } from '../Landing/actions';
import PropertyListBlock from '../common/Property/propertyListBlock';
import { useHistory, Link } from "react-router-dom"
import Pagination from '../../Pagination';
import NewPagination from '../../NewPagination';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import request from '../../service/request';

function SkeletonCustom(props) {
    return (
        <>
            {/* <div className="servicesbox-content arrowtop_right">
                <div className="category-carousel">
                    <div className="slider_itemBox">
                        <a className={props.parentClassName}><Skeleton className={props.classes} width={props.width} height={props.height} inline={props.inline} count={props.count} style={{ height: "300px", margin: "10px" }} /></a>
                    </div>
                </div>
            </div> */}

            <div className={`  ${props.parentClassName}`} >
                <Skeleton className={props.classes} width={props.width} height={props.height} inline={props.inline} count={props.count} style={{ height: "450px" }} />
            </div>

        </>
    );
}

const Search = (props) => {
    const newselectedOptions = useSelector(store => store.landingPageData.selectedOptions > 0 ? store.landingPageData.selectedOptions : store.landingPageData.selectedOptions)
    const properties = useSelector(store => store.landingPageData.propertiesByFilter > 0 ? store.landingPageData.propertiesByFilter : store.landingPageData.propertiesByFilter)
    const loading = useSelector(store => store.landingPageData.isPropertyListLoading == false ? store.landingPageData.isPropertyListLoading : store.landingPageData.isPropertyListLoading)

    const dispatch = useDispatch();
    const history = useHistory();
    const [viewName, setViewName] = useState('gridView');
    const [totalSearch, setTotalSearch] = useState('');
    const [propertyData, setProperty] = useState([]);
    const [toggleTopSelling, setToggleTopSelling] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [pagingMeta, setPagingMeta] = useState(false);
    const [lastPage, setLastPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [choosedSortBy, setChoosedSortBy] = useState('latest');
    const [toggleSearchMenu, setToggleSearchMenu] = useState(null);
    const [menu, setMenu] = useState([])
    const pageName = history.location.pathname.split('/').map((i) => _.startCase(_.camelCase(i))).join(' ')
    let sortByOptions = [{ id: 'latest', name: 'Latest' }, { id: 'price_low_to_high', name: 'Price(low to high)' }, { id: 'price_high_to_low', name: 'Price(high to low)' }];

    /** Code for directly hit the query string in browser */
    const searchData = history.location.search;
    const q = new URLSearchParams(searchData);
    const params = Object.fromEntries(q.entries());

    useEffect(() => {
        if (properties && properties.data) {
            setTotalSearch(properties?.meta?.total);
            setLastPage(properties?.meta?.last_page);
            setPagingMeta(properties.meta);
            setProperty(properties.data);
            setIsLoading(false)
        }

        /* if (typeof properties === 'object' && Object.keys(properties).length > 0) {
            setTotalSearch(Object.keys(properties).length);
            setProperty(properties);
        } */
    }, [properties]);

    useEffect(() => {
        setChoosedSortBy("latest");
        let cparams = { ...params, "sort_by": "latest" };
        dispatch(setSelectedOptionsRequest(cparams));
        if (typeof cparams === 'object' && Object.keys(cparams).length > 0) {
            // dispatch the properties list by selected filter options..
            // QueryString = getQueryString(initialOptions);
            dispatch(fetchPropertiesByFilterRequest(JSON.stringify(cparams)))
        }
    }, [props]);

    useEffect(() => {
        request({
            method: 'get',
            url: '/get-menu',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
        })
            .then((response) => {
                setMenu(response?.data);
            })
            .catch(({ data }) => ({ error: data }));
    }, []);

    useEffect(() => {
        let newParam = { ...params, "page": currentPage }
        newParam = { ...newParam, "sort_by": choosedSortBy }
        dispatch(fetchPropertiesByFilterRequest(JSON.stringify(newParam)))
    }, [currentPage, choosedSortBy]);

    const closeToggleTopSelling = () => {
        setToggleTopSelling(!toggleTopSelling);
    }

    const handleListView = (e) => {
        e.preventDefault();
        setViewName("listView");
    }

    const handleGridView = (e) => {
        e.preventDefault();
        setViewName('gridView');
    }

    const handleSortChoosedBy = (item) => {
        setChoosedSortBy(item.id);
    }

    const handleToggleSearchMenu = () => {
        if (!toggleSearchMenu) {
            setToggleSearchMenu(true);
        } else {
            setToggleSearchMenu(false);
        }
    }

    return (
        <>
            <Helmet>
                <title>{`${menu.data?.site_setting?.site_name} - ${pageName}`}</title>
            </Helmet>
            {/* <!-- START SECTION PROPERTIES LISTING GRID VIEW--> */}
            <section className={(viewName == 'gridView' ? "properties-list featured portfolio blog" : "d-none")}>
                <div className="container">
                    <section className="headings-2 pt-0 pb-0">
                        <div className="pro-wrapper row w-auto">
                            <div className="detail-wrapper-body col-lg-12 col-md-12 col-sm-12 d-flex wrap justify-content-between">
                                <div className="listing-title-bar">
                                    <div className="text-heading text-left">
                                        {/* <p><Link to={"/"}>Home </Link> <span>{history.location.pathname.split('/').map((i) => _.startCase(_.camelCase(i))).join(' / ')}</span></p> */}
                                    </div>
                                    <h3>{pageName}</h3>
                                </div>
                                <Button className='btn btn-yellow customButton' onClick={handleToggleSearchMenu}>{toggleSearchMenu == true ? "Close" : "Search"}</Button>
                            </div>
                        </div>
                    </section>

                    <div className={toggleSearchMenu ? `'explore__form-checkbox-list propertylist-transition-start  d-lg-none d-xl-flex filter-block p-0'` : ((toggleSearchMenu !== null) ? `propertylist-transition` : `d-none`)}>
                        <SearchFilters />
                    </div>

                    <section className="headings-2 pt-0">
                        <div className="pro-wrapper">
                            <div className="detail-wrapper-body">
                                <div className="listing-title-bar">
                                    <div className="text-heading text-left">
                                        <p className="font-weight-bold mb-0 mt-3">{totalSearch} Search results</p>
                                    </div>
                                </div>
                            </div>
                            <div className="cod-pad single detail-wrapper mr-2 mt-0 d-flex justify-content-md-end align-items-center">
                                <div className='input-group border rounded input-group-lg w-auto mr-4'>
                                    <label className="input-group-text bg-transparent border-0 text-uppercase letter-spacing-093 pr-1 pl-3" htmlFor="inputGroupSelect01">
                                        <i className="fas fa-align-left fs-16 pr-2"></i>Sortby:</label>
                                    <select className="form-control border-0 bg-transparent shadow-none p-0 selectpicker sortby" data-style="bg-transparent border-0 font-weight-600 btn-lg pl-0 pr-3" id="inputGroupSelect01" name="sortby" style={{ display: "none" }}>
                                        <option defaultValue="">Top Selling</option>
                                        <option value="1">Most Viewed</option>
                                        <option value="2">Price(low to high)</option>
                                        <option value="3">Price(high to low)</option>
                                    </select>
                                    <div className={`nice-select form-control border-0 bg-transparent shadow-none p-0 selectpicker sortby ${toggleTopSelling ? 'open' : ''}`} onClick={closeToggleTopSelling} onBlur={() => { setToggleTopSelling(false) }} tabIndex="0">
                                        <span className="current">{_.find(sortByOptions, ["id", choosedSortBy])?.name}</span>
                                        <ul className="list">
                                            {
                                                sortByOptions && sortByOptions.length > 0 && sortByOptions.map((item) => {
                                                    return <li data-value="1" onClick={handleSortChoosedBy.bind(null, item)} className={`option ${(choosedSortBy == item.id) ? 'selected' : ''}`}>{item.name}</li>
                                                })
                                            }
                                            {/* <li data-value="Top Selling" onClick={() => { setChoosedSortBy('Top Selling') }} className="option selected focus">Top Selling</li> */}

                                            {/* <li data-value="2" onClick={() => { setChoosedSortBy('price_low_to_high') }} className="option">Price(low to high)</li>
                                            <li data-value="3" onClick={() => { setChoosedSortBy('price_high_to_low') }} className="option">Price(high to low)</li> */}
                                        </ul>
                                    </div>

                                </div>
                                <div className="sorting-options">
                                    <a onClick={handleListView} href="#" className="change-view-btn lde"><i className="fa fa-th-list"></i></a>
                                    <a onClick={handleGridView} href="#" className="change-view-btn active-view-btn"><i className="fa fa-th-large"></i></a>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* property grid block start */}
                    <div className='row featured portfolio-items'>
                        {
                            isLoading == true ?
                                <>
                                    <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                                        <SkeletonCustom parentClassName="productListSketelonParent" inline={true} count={1} />
                                    </div>
                                    <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                                        <SkeletonCustom parentClassName="productListSketelonParent" inline={true} count={1} />
                                    </div>
                                    <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                                        <SkeletonCustom parentClassName="productListSketelonParent" inline={true} count={1} />
                                    </div>
                                    <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                                        <SkeletonCustom parentClassName="productListSketelonParent" inline={true} count={1} />
                                    </div>
                                    <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                                        <SkeletonCustom parentClassName="productListSketelonParent" inline={true} count={1} />
                                    </div>
                                </>
                                :
                                propertyData && propertyData.length > 0 && propertyData.map((item, index) => {
                                    return (
                                        <PropertyListBlock key={`propertyBlockData- ${index}`} property={item} columnClass={`col-xl-4 col-lg-4 col-md-6 col-xs-12`} />
                                    )
                                })
                        }
                    </div>
                    {/* property grid block end */}
                    {/* pagination start */}
                    {
                        pagingMeta ?
                            <NewPagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={pagingMeta.last_page}
                                pageSize={1}
                                // onPageChange={setCurrentPage}
                                onPageChange={page => setCurrentPage(page)}
                            /> : ''
                    }
                </div>
            </section>
            {/* <!-- END SECTION PROPERTIES LISTING GRID VIEW-->      */}
            {/* <!-- START SECTION PROPERTIES LISTING LIST VIEW --> */}
            <section className={(viewName == 'listView' ? "properties-list full-l featured portfolio blog" : "d-none")}>
                <div className="container">
                    <section className="headings-2 pt-0 pb-0">
                        <div className="pro-wrapper row w-auto">
                            <div className="detail-wrapper-body col-lg-12 col-md-12 col-sm-12 d-flex wrap justify-content-between">
                                <div className="listing-title-bar">
                                    <div className="text-heading text-left">
                                        {/* <p><a href="index.html">Home </a> &nbsp;/&nbsp; <span>Listings</span></p> */}
                                    </div>
                                    <h3>{pageName}</h3>
                                </div>
                                <Button className='btn btn-yellow customButton' onClick={handleToggleSearchMenu}>{toggleSearchMenu == true ? "Close" : "Search"}</Button>
                            </div>
                        </div>
                    </section>
                    <div className={`${toggleSearchMenu ? 'explore__form-checkbox-list d-lg-none d-xl-flex filter-block p-0' : 'd-none'}`}>
                        <SearchFilters />
                    </div>
                    {/* <!-- Search Form [ filter option block ] --> */}
                    {/* <!--/ End Search Form [ filter option block ]--> */}
                    <section className="headings-2 pt-0">
                        <div className="pro-wrapper">
                            <div className="detail-wrapper-body">
                                <div className="listing-title-bar">
                                    <div className="text-heading text-left">
                                        <p className="font-weight-bold mb-0 mt-3">{totalSearch} Search results</p>
                                    </div>
                                </div>
                            </div>
                            <div className="cod-pad single detail-wrapper mr-2 mt-0 d-flex justify-content-md-end align-items-center">
                                <div className='input-group border rounded input-group-lg w-auto mr-4'>
                                    <label className="input-group-text bg-transparent border-0 text-uppercase letter-spacing-093 pr-1 pl-3" htmlFor="inputGroupSelect01">
                                        <i className="fas fa-align-left fs-16 pr-2"></i>Sortby:</label>
                                    <select className="form-control border-0 bg-transparent shadow-none p-0 selectpicker sortby" data-style="bg-transparent border-0 font-weight-600 btn-lg pl-0 pr-3" id="inputGroupSelect01" name="sortby" style={{ display: "none" }}>
                                        <option defaultValue="">Top Selling</option>
                                        <option value="1">Most Viewed</option>
                                        <option value="2">Price(low to high)</option>
                                        <option value="3">Price(high to low)</option>
                                    </select>
                                    <div className={`nice-select form-control border-0 bg-transparent shadow-none p-0 selectpicker sortby ${toggleTopSelling ? 'open' : ''}`} onClick={closeToggleTopSelling} onBlur={() => { setToggleTopSelling(false) }} tabIndex="0">
                                        <span className="current">{_.find(sortByOptions, ["id", choosedSortBy]).name}</span>
                                        <ul className="list">
                                            {
                                                sortByOptions && sortByOptions.length > 0 && sortByOptions.map((item) => {
                                                    return <li data-value="1" onClick={handleSortChoosedBy.bind(null, item)} className={`option ${(choosedSortBy == item.id) ? 'selected' : ''}`}>{item.name}</li>
                                                })
                                            }
                                        </ul>
                                    </div>

                                </div>
                                <div className="sorting-options">
                                    <a onClick={handleListView} href="#" className="change-view-btn active-view-btn"><i className="fa fa-th-list"></i></a>
                                    <a onClick={handleGridView} href="#" className="change-view-btn lde"><i className="fa fa-th-large"></i></a>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* property list block start */}
                    <div className="row featured portfolio-items property-card-size">
                        {
                            propertyData && propertyData.length > 0 && propertyData.map((item, index) => {
                                return (
                                    <React.Fragment key={`property-data-2 -${index}`}>
                                        <PropertyListBlock property={item} columnClass={`col-xl-12 col-lg-12 col-md-12 col-xs-12`} columnClass2={`card-margin`} />
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                    {/* property list block end */}
                    {/* pagination start */}
                    {
                        pagingMeta ?
                            <NewPagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={pagingMeta.last_page}
                                pageSize={1}
                                // onPageChange={setCurrentPage}
                                onPageChange={page => setCurrentPage(page)}
                            /> : ''
                    }
                    {/* pagination end */}
                </div>
            </section>
            {/* <!-- END SECTION PROPERTIES LISTING LIST VIEW--> */}


        </>
    )
}

export default Search
