import React, { useState, useEffect } from "react";
import image from "../../../rels_assets/images/blog/b-11.jpg";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { Button } from "react-bootstrap";

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

const PropertyListBlock = (props) => {
    const dispatch = useDispatch();
    const [newproperty, setnewProperty] = useState([]);
    const [propertyImage, setPropertyImage] = useState([]);
    useEffect(() => {
        // if(props.property && props.property.length > 0) {
        if (typeof props.property === 'object' && Object.keys(props.property).length > 0) {
            setnewProperty(props.property);
            scrollToTop();
        }
    }, [props.property]);

    useEffect(() => {
        setPropertyImage((newproperty && newproperty.property_images && newproperty.property_images.length > 0) ? `'${newproperty.property_images[0].image}'` : image)
    }, [newproperty])

    return (
        <>
            {/* <Link to={`/property/${newproperty.id}`}> */}
            <Link to={`/property/${newproperty.id}`} className={`item text-decoration-none id-${newproperty.id} ${props.columnclassName} ${props.columnClass ? props.columnClass : ''} landscapes1 ${newproperty.property_for?.name}`}>
                <div className="project-single" data-aos="fade-right">

                    <div className="project-inner project-head property-card-image" style={{

                        backgroundImage: `url(${propertyImage})`,
                        backgroundImage: `-webkit-image-set(url(${propertyImage}) 1x, url(${propertyImage}) 2x)`,
                        backgroundImage: `image-set(url(${propertyImage}) 1x, url(${propertyImage}) 2x)`,
                        backgroundSize: 'cover,contain'
                    }}>
                        <div className="homes">
                            <a className="homes-img" aria-label={`homes-${newproperty.id}`}>
                                {
                                    newproperty.hot_property == 1 &&
                                    <div className="homes-tag button alt featured">
                                        Hot
                                    </div>
                                }

                                <div className={`homes-tag button alt sale ${newproperty.property_for?.name}`}>
                                    {newproperty.property_for?.name}
                                </div>
                                {/* <img
                                    src={(newproperty && newproperty.property_images && newproperty.property_images.length > 0)?newproperty.property_images[0].image:image}
                                    alt="home-1"
                                    className="img-responsive"
                                /> */}
                            </a>
                        </div>
                        <div className="button-effect pr-2">
                            <Link to={`/property/${newproperty.id}`} className="btn" aria-label={`Property ${newproperty.id}`}>
                                <i className="fa fa-link"></i>
                            </Link>
                        </div>
                    </div>

                    <div className="homes-content ">
                        <h3></h3>
                        <p className="propertyList-address mb-3 text-truncate pr-3">
                            <a className="text-decoration-none" aria-label={`${newproperty.location}`}>
                                {/* <i className="fa fa-map-marker"></i> */}
                                <span className="" title={newproperty?.location}>
                                    {newproperty?.location}
                                </span>
                            </a>
                        </p>

                        <ul className="homes-list clearfix pb-3">
                            {
                                newproperty.property_config &&
                                <>
                                    <li className="the-icons">
                                        <i
                                            className="flaticon-bed mr-2"
                                            aria-hidden="true"
                                        ></i>
                                        <span>{newproperty.property_config?.name}</span>
                                    </li>
                                </>
                            }

                            {
                                newproperty.built_up_area &&
                                <>
                                    <li className="the-icons">
                                        <i
                                            className="flaticon-square mr-2"
                                            aria-hidden="true"
                                        ></i>
                                        <span>{newproperty?.built_up_area}</span>
                                    </li>
                                </>
                            }
                        </ul>
                        <div className={`price-properties footer pt-3 pb-0 ${props.columnClass2 ? props.columnClass2 : ''}`}>
                            {
                                ((newproperty && newproperty?.price && newproperty?.price?.length > 0) && (newproperty && newproperty?.show_price_in_website !== 0)  ? <h3 className="title mt-3">{newproperty?.price}</h3> : <Link className="btn  btn-md ask-price-btn" to={`/property/${newproperty.id}`}>Ask For Price</Link>)
                            }
                        </div>



                    </div>
                </div>
            </Link>
            {/* </Link> */}
        </>
    );
};

export default PropertyListBlock;
