import React, { useEffect, useState } from 'react'
import "../../../src/rels_assets/css/menu.css";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from "react-router-dom";
import logo from "./../../rels_assets/images/logo-white-1.svg";
import redlogo from "./../../rels_assets/images/logo-red.svg";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import request from '../../service/request';
import { toggleMenuRequest } from './actions';
import { ClickAwayListener } from '@material-ui/core';
import { getQueryString } from '../../helper';
import { fetchPropertiesByFilterRequest, setSelectedOptionsRequest } from '../Landing/actions';
import _ from 'lodash';

const Header = (props) => {
    // const [menu, setMenu] = useState([]);
    const location = useLocation();
    const menu = useSelector(store => store.commonPageData.menu.length > 0 ? store.commonPageData.menu : store.commonPageData.menu)
    const [settings, setSetting] = useState([]);
    const [defaultPages, setDefaultPage] = useState([]);
    const [otherPages, setOtherPage] = useState([]);
    const toggleMenu = useSelector(store => store.commonPageData.toggleMenu)
    const [toggleOptions, setToggleOptions] = useState(false);
    const [stickyClass, setStickyClass] = useState('');
    const [openSubPages, setOpenSubPages] = useState(false);
    let allSelectedOption = useSelector(store => store.landingPageData.selectedOptions);
    const dispatch = useDispatch();
    const history = useHistory();
    const initialparams = {
        hot_property: '',
        property_type: '',
        property_for: '',
        specific_property: '',
        measurement: '',
        area_size: '',
        area: '',
        country: 101,
        state: '',
        city: '',
        page: 1,
        sort_by: 'latest',
        property_configuration: ''
    };
    useEffect(() => {
        window.addEventListener('scroll', stickNavbar, { passive: true })
        return () => window.removeEventListener('scroll', stickNavbar);
    }, [])



    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            (windowHeight > 250) ? setStickyClass('cloned sticky') : setStickyClass('');
        }
    };
    /* useEffect(()=>{
        request({
            method: 'get',
            url: '/get-menu',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
        })
            .then((response) => {
                setMenu(response?.data);
            })
            .catch(({ data }) => ({ error: data }));
    },[]); */

    useEffect(() => {
        if (menu && menu.code == 0) {
            setSetting(menu.data.site_setting);
            setDefaultPage(menu.data.pages.default_pages)
            setOtherPage(menu.data.pages.other_pages)
        }
    }, [menu]);

    useEffect(() => {
        setToggleOptions(toggleMenu);
    }, [toggleMenu]);

    const handleMenuClick = (event, filters = '') => {
        event.preventDefault();
        if (event.type === 'click') {
            let historypath = '/all/' + filters;
            let QueryString = "";
            if (filters != '' && filters == 'hot-property') {
                allSelectedOption.hot_property = true;
                historypath = '/' + filters;
            }
            if (filters != '' && filters == 'property') {
                allSelectedOption = initialparams;
            }
            dispatch(setSelectedOptionsRequest(allSelectedOption));
            if (typeof allSelectedOption === 'object' && Object.keys(allSelectedOption).length > 0) {
                QueryString = getQueryString(allSelectedOption);
                // dispatch the properties list by selected filter options..
                dispatch(fetchPropertiesByFilterRequest(JSON.stringify(allSelectedOption)))
            }
            history.push({
                pathname: historypath,
                search: `?${QueryString}`
            })
        }
    }

    const openMobileMenu = (e) => {
        e.preventDefault();
        dispatch(toggleMenuRequest());
        setToggleOptions(!toggleOptions);
    }

    const handleClickForProperties = (event) => {
        handleMenuClick(event, 'property');
    }

    const handleClickforhotProperties = (event) => {
        handleMenuClick(event, 'hot-property')
    }
    const prioritizedSlug = 'customer-list';
    // Separate the prioritized item
    const prioritizedPage = defaultPages && defaultPages.find(dpage => dpage.slug === prioritizedSlug);

    // Function to determine if the page should be shown
    const shouldShowPage = (dpage) => dpage.show_page_in === 'on_top_menu' || dpage.show_page_in === 'on_both';

    return (
        <>
            <div>
                <HelmetProvider>
                    <Helmet
                        bodyAttributes={{
                            class: ((location.pathname == "/")) ? 'homepage-9 hp-6 homepage-1 mh' : 'inner-pages homepage-4 homepage-1 agents hp-6 full hd-white'
                        }}
                        htmlAttributes={{ class: `${toggleOptions ? 'mm-opened mm-blocking mm-background mm-opening' : ''}` }}
                        link={[
                            {
                                "rel": "icon",
                                "type": "image/png",
                                "href": `${settings.icon_logo ? settings.icon_logo : ``}`
                            }
                        ]}
                    />
                </HelmetProvider>
            </div>

            <header id="header-container" className={location.pathname == "/" ? "header head-tr " : " "}>
                {/* <!-- Header --> */}
                <div id="header" className={`head-tr bottom ${stickyClass}`}>
                    <div className="container container-header">
                        {/* <!-- Left Side Content --> */}
                        <div className="left-side d-xl-flex">
                            {/* <!-- Logo --> */}
                            <div id="logo">
                                {
                                    stickyClass ? <Link to={"/"} aria-label="">{(settings.logo ? <img loading="lazy" src={location.pathname == "/" ? settings?.logo : settings?.logo} data-sticky-logo={settings?.logo} alt="logoImg" /> : '')}</Link>
                                        :
                                        <Link to={`/`} aria-label="">{(settings.logo ? <img loading="lazy" src={location.pathname == "/" ? settings?.logo : settings?.logo} data-sticky-logo={settings?.logo} alt="logoImg" /> : '')}</Link>
                                }
                            </div>
                            {/* <!-- Mobile Navigation --> */}
                            <div className={`mmenu-trigger${toggleOptions ? ' mm-button-open-1' : ''}`} onClick={openMobileMenu}  /* onBlur={(e) => { e.preventDefault();if(toggleOptions){setToggleOptions(false); dispatch(toggleMenuRequest())}}} */>
                                <button className={`hamburger hamburger--collapse${toggleOptions ? ' is-active' : ''}`} type="button">
                                    <span className="hamburger-box">
                                        <span className="hamburger-inner">&nbsp;</span>
                                    </span>
                                </button>
                            </div>
                            {/* <!-- Main Navigation --> */}
                            <nav id="navigation" className={location.pathname == "/" ? "style-1 head-tr mt-0" : "style-1 mt-0"}>
                                <ul id="responsive">
                                    <li><Link aria-label="" to={`/`}>Home</Link></li>
                                    <li><Link aria-label="" to={`/all/property`} onClick={handleClickForProperties}>All Property</Link></li>
                                    <li><Link aria-label="" to={`/hot-property`} onClick={handleClickforhotProperties}>Hot Property</Link></li>
                                    <li><Link area-label="" to={`/preleased-property`} >Pre-Leased Property</Link></li>
                                    <li><Link aria-label="" to={`/building/project`}>Building/Project</Link></li>
                                    {/* <li><Link aria-label="" to={`/page/customer-list`}>Customer List</Link></li> */}
                                    {/* default pages - start */}
                                    {prioritizedPage && shouldShowPage(prioritizedPage) && (
                                        <li key={prioritizedPage.slug}>
                                            <Link to={`/page/${prioritizedPage.slug}`} aria-label="">{prioritizedPage.name}</Link>
                                        </li>
                                    )}
                                    {defaultPages && defaultPages.length > 0 && defaultPages.map((dpage) => {
                                        return (
                                            (dpage.show_page_in == 'on_top_menu' || dpage.show_page_in == 'on_both' && dpage.slug !== prioritizedSlug)  &&
                                            <li key={dpage.slug}>
                                                <Link to={`/page/${dpage.slug}`} aria-label="">{dpage.name}</Link>
                                            </li>
                                        )
                                    })}
                                    {/* default pages - end */}


                                    {/* pages dropdown - start */}
                                    {otherPages && otherPages.length > 0 && (_.filter(otherPages, function (o) { if (o.show_page_in == 'on_top_menu' || o.show_page_in == 'on_both') return o }).length) > 0 &&
                                        <li>
                                            <a href="#" aria-label="">More</a>
                                            <ul>
                                                {otherPages && otherPages.length > 0 && otherPages.map((opage) => {
                                                    return (
                                                        (opage.show_page_in == 'on_top_menu' || opage.show_page_in == 'on_both') &&
                                                        <li key={opage.slug}>
                                                            <Link to={`/page/${opage.slug}`} aria-label="">{opage.name}</Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </li>
                                    }
                                    {/* pages dropdown - end */}

                                    {/* <li><Link to={'/page/about-us'}>About Us</Link></li>
        <li><Link to={'/page/contact-us'}>Contact Us</Link></li> */}
                                    {/* <li className="d-none d-xl-none d-block d-lg-block"><a href="login.html">Login</a></li>
                                    <li className="d-none d-xl-none d-block d-lg-block"><a href="register.html">Register</a></li>
                                    <li className="d-none d-xl-none d-block d-lg-block mt-5 pb-4 ml-5 border-bottom-0"><a href="javasc"  aria-label="" className="button border btn-lg btn-block text-center">Add Listing<i className="fas fa-laptop-house ml-2"></i></a></li> */}
                                </ul>
                            </nav>
                        </div>
                        {/* <!-- Left Side Content / End --> */}

                        <div className="right-side d-none d-none d-lg-none d-xl-flex">
                        </div>
                    </div>
                </div>
                {/* <!-- Header / End --> */}

            </header>
            <div className="clearfix"></div>

        </>
    )
}

export default Header
