import { useEffect, useState } from "react";
import request from "../../service/request";
import image from "../../rels_assets/images/blog/b-11.jpg";
import _ from 'lodash';
import { useHistory, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import $, { data } from 'jquery';
import { Helmet } from "react-helmet";
import NewPagination from "../../NewPagination";
import { fetchPropertiesByFilterRequest } from "../Landing/actions";
import SearchFilters from "../common/Hero/SearchFilters";
import { Button } from "react-bootstrap";

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

const Preleased = () => {
    const properties = useSelector(store => store.landingPageData?.pre_leased > 0 ? store.landingPageData?.propertiesByFilter : store.landingPageData?.propertiesByFilter)
    const history = useHistory();
    const dispatch = useDispatch();
    const [newproperty, setnewProperty] = useState([]);
    const [propertyImage, setPropertyImage] = useState([]);
    const [totalSearch, setTotalSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1)
    const [pageData, setPageData] = useState([]);
    const [pagingMeta, setPagingMeta] = useState(false);
    const [lastPage, setLastPage] = useState(1);
    const [menu, setMenu] = useState([]);
    const [pageMeta, setPageMeta] = useState([]);
    const [toggleSearchMenu, setToggleSearchMenu] = useState(null);
    const pageName = history.location.pathname.split('/').map((i) => _.startCase(_.camelCase(i))).join(' ')
    const pageslug = 'Pre-leased Property';

    useEffect(() => {
        scrollToTop();
        getPageData();
    }, []);

    useEffect(() => {
        request({
            method: 'post',
            url: '/get-pre-leased-property',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
        })
            .then((response) => {
                setnewProperty(response?.data.data);
                setTotalSearch(response?.data?.meta?.total);
                setPageMeta(response?.data?.meta)
                setLastPage(response?.data?.meta?.last_page);
                setPagingMeta(response?.data?.meta);
            })
            .catch(({ data }) => ({ error: data }));
    }, []);

    useEffect(() => {
        request({
            method: 'post',
            url: '/get-pre-leased-property',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
            data: { page: currentPage }
        })
            .then((response) => {
                setnewProperty(response?.data.data);
                setTotalSearch(response?.data?.meta?.total);
                setPageMeta(response?.data?.meta)
                setLastPage(response?.data?.meta?.last_page);
                setPagingMeta(response?.data?.meta);
            })
            .catch(({ data }) => ({ error: data }));
    }, [currentPage]);

    const getPageData = () => {
        request({
            method: 'post',
            url: 'pages/get-page-by-slug',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
            data: { 'slug': pageslug },
        })
            .then((response) => {
                setPageData(response.data);
            })
            .catch(({ data }) => {
            });
    }

    useEffect(() => {
        request({
            method: 'get',
            url: '/get-menu',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
        })
            .then((response) => {
                setMenu(response?.data);
            })
            .catch(({ data }) => ({ error: data }));
    }, []);

    useEffect(() => {
        setPropertyImage((newproperty && newproperty.property_images && newproperty.property_images.length > 0) ? `'${newproperty.property_images[0].image}'` : image)
    }, [newproperty, pageData])

    const handleToggleSearchMenu = () => {
        if (!toggleSearchMenu) {
            setToggleSearchMenu(true);
        } else {
            setToggleSearchMenu(false);
        }
    }


    return (
        <>
            <Helmet>
                <title>{`${menu?.data?.site_setting?.site_name} - ${pageName.replace('/', '')}`}</title>
                <meta name="description" content={pageData && pageData?.data && Object.keys(pageData?.data).length > 0 && pageData?.data.meta_description} />
            </Helmet>
            <section className="properties-list featured portfolio blog">
                <div className="container">
                    <section class="headings-2 pt-0 pb-0">
                        <div class="pro-wrapper row w-auto">
                            <div class="detail-wrapper-body col-lg-12 col-md-12 col-sm-12 d-flex wrap justify-content-between">
                                <div class="listing-title-bar">
                                    <div class="text-heading text-left">
                                    </div>
                                    <h3>{pageName}</h3>
                                </div>
                                <Button className='btn btn-yellow customButton' onClick={handleToggleSearchMenu}>{toggleSearchMenu == true ? "Close" : "Search"}</Button>
                            </div>
                        </div>
                    </section>

                    <div className={toggleSearchMenu ? `'explore__form-checkbox-list propertylist-transition-start  d-lg-none d-xl-flex filter-block p-0'` : ((toggleSearchMenu !== null) ? `propertylist-transition` : `d-none`)}>
                        <SearchFilters />
                    </div>

                    <section class="headings-2 pt-0">
                        <div class="pro-wrapper">
                            <div class="detail-wrapper-body">
                                <div class="listing-title-bar">
                                    <div class="text-heading text-left">
                                        <p class="font-weight-bold mb-0 mt-3">{pageMeta?.total} Search results</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="row featured portfolio-items">
                        {
                            newproperty && newproperty.length > 0 && newproperty.map((property) => {
                                return (
                                    <>
                                        {/* <Link to={`/property/${newproperty.id}`}> */}
                                        <Link to={`/property/${property.id}`} className={`item text-decoration-none  col-xl-4 col-lg-4 col-md-6 col-xs-12 landscapes1 `}>
                                            <div className="project-single" data-aos="fade-right">

                                                <div className="project-inner project-head property-card-image" style={{

                                                    backgroundImage: `url(${propertyImage})`,
                                                    backgroundImage: `-webkit-image-set(url(${propertyImage}) 1x, url(${propertyImage}) 2x)`,
                                                    backgroundImage: `image-set(url(${propertyImage}) 1x, url(${propertyImage}) 2x)`,
                                                    backgroundSize: 'cover,contain'
                                                }}>
                                                    <div className="homes">
                                                        <a className="homes-img" aria-label={`homes-${property.id}`}>
                                                            {
                                                                property.hot_property == 1 &&
                                                                <div className="homes-tag button alt featured">
                                                                    Hot
                                                                </div>
                                                            }


                                                            <div className={`homes-tag button alt sale ${property.property_for?.name}`}>
                                                                {property.property_for?.name}
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div className="button-effect pr-2">
                                                        <Link to={`/property/${property.id}`} className="btn" aria-label={`Property ${property.id}`}>
                                                            <i className="fa fa-link"></i>
                                                        </Link>
                                                    </div>
                                                </div>

                                                <div className="homes-content ">
                                                    <h3></h3>
                                                    <p className="propertyList-address mb-3 text-truncate pr-3">
                                                        <a className="text-decoration-none" aria-label={`${property.location}`}>
                                                            {/* <i className="fa fa-map-marker"></i> */}
                                                            <span className="" title={property?.location}>
                                                                {property?.location}
                                                            </span>
                                                        </a>
                                                    </p>

                                                    <ul className="homes-list clearfix pb-3">
                                                        {
                                                            property.property_config &&
                                                            <>
                                                                <li className="the-icons">
                                                                    <i
                                                                        className="flaticon-bed mr-2"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                    <span>{property.property_config?.name}</span>
                                                                </li>
                                                            </>
                                                        }

                                                        {
                                                            property.built_up_area &&
                                                            <>
                                                                <li className="the-icons">
                                                                    <i
                                                                        className="flaticon-square mr-2"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                    <span>{property?.built_up_area}</span>
                                                                </li>
                                                            </>
                                                        }
                                                    </ul>
                                                    <div className={`price-properties footer pt-3 pb-0`}>
                                                        <h3 className="title mt-3">{property?.price}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                        {/* </Link> */}
                                    </>
                                )
                            })
                        }
                    </div>
                    {
                        pagingMeta ?
                            <NewPagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={pagingMeta.last_page}
                                pageSize={1}
                                // onPageChange={setCurrentPage}
                                onPageChange={page => setCurrentPage(page)}
                            /> : ''
                    }
                </div>
            </section>
        </>
    )
}

export default Preleased;