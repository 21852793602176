import axios from 'axios';
let userDetails = (sessionStorage.userDetails) ? JSON.parse(sessionStorage.userDetails) : null;
let header = (userDetails && userDetails.api_token)?{ "Content-Type": "application/json", "Accept": "application/json", "Authorization": `Bearer ${userDetails.api_token}` }:{ "Content-Type": "application/json", "Accept": "application/json" };
const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_API_ENDPOINT_SUBPATH}`,
  headers: header,
});
const client2 = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_API_ENDPOINT_SUBPATH}`
});

const request = options => {
  return client(options)
    .then(response => response)
    .catch(error => Promise.reject(error.response || error.message));
};
const request2 = options => {
  return client2(options)
    .then(response => response)
    .catch(error => Promise.reject(error.response || error.message));
};

export default request;

