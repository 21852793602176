import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom"
import testimonialBg from '../../rels_assets/img/page/error-404.jpg'
import { Helmet, HelmetProvider } from "react-helmet-async";
function PageNotFound() {

    const [userSettings, setUserSettings] = useState(null);
    const sessionData = sessionStorage.getItem("userDetails");

    return (
        <>
            <div>
                <HelmetProvider>
                    <Helmet>
                        <meta name="robots" content="noindex" />
                    </Helmet>
                </HelmetProvider>
            </div>
            <main>
                <div className="breadcrumb-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-wrap">
                                    <nav aria-label="breadcrumb">
                                        <ul className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="index.html"><i className="fa fa-home"></i></a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Page not found</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="about-us section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="about-thumb">
                                    <img loading='lazy' src={testimonialBg} alt="about thumb" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="about-content">
                                    <h2 className="about-title">Page Not Found!</h2>
                                    <h5 className="about-sub-title">
                                        Oops! Looks Like Something Going Wrong We can’t seem to find the page you’re looking for make sure that you have typed the currect URL
                                    </h5>
                                    <Link className="btn btn-sqr btn-lg p-3" to={'/'}>Go To Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default PageNotFound;
