import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import request from '../../service/request';

const scrollToTop = () => {
	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});
};

function TermsCondition() {
	const customLocation = useLocation();
	const [pageData, setPageData] = useState([]);
	const [pageNotExist, setPageNotExist] = useState(false);
	const [bannerImage, setBannerImage] = useState([]);
	const pageslug = 'terms-&-conditions';

	useEffect(() => {
		scrollToTop();
		getPageData();
	}, []);

	const getPageData = () => {
		request({
			method: 'post',
			url: 'pages/get-page-by-slug',
			headers: { "Content-Type": "application/json", "Accept": "application/json" },
			data: { 'slug': pageslug }
		})
			.then((response) => {
				setPageData(response.data);
			})
			.catch(({ data }) => {
				setPageNotExist(true);
			});
	}

	return (
		<>
			<div>
				<HelmetProvider>
					<Helmet
						bodyAttributes={{
							class: ((customLocation.pathname == "/")) ? 'homepage-9 hp-6 homepage-11 mh' : 'inner-pages homepage-4 homepage-1 agents hp-6 full hd-white about'
						}}>
						<title>{(pageData && pageData?.data && Object.keys(pageData?.data).length > 0) ? pageData?.data?.meta_title : `${pageData?.data?.slug}`}</title>
						<meta name="description" content={pageData && pageData?.data && Object.keys(pageData?.data).length > 0 && pageData?.data.meta_description} />
						{
							pageData?.data &&
							<script type="application/ld+json">
								{JSON.stringify({
									"@context": "https://schema.org/",
									"@type": "Website",
									"name": pageData?.data.meta_title,
									"description": pageData?.data.meta_description,
								})}
							</script>
						}
					</Helmet>
				</HelmetProvider>
			</div>
			<main id="main">
				<section className="small-space">
					<div className="container">
						<div className="content-wrapper space-large">
							<div className="row">
								<div className='col-12'>
									<h1 className="title1 custom-pages-title my-5"><strong>TERMS & CONDITIONS</strong></h1>
								</div>
								{
									pageData?.data?.page_description &&
									<div className="col-12" dangerouslySetInnerHTML={{ __html: pageData?.data?.page_description }} >
									</div>
								}
							</div>
						</div>
					</div>
				</section>
			</main>

		</>
	)
}

export default TermsCondition;