import {takeLatest} from 'redux-saga/effects'
import {GET_HOT_PROPERTIES_REQUEST,GET_POPULAR_PROJECTS_REQUEST,GET_OUR_SERVICES_REQUEST, GET_OUR_AGENTS_REQUEST, GET_TESTIMONIALS_REQUEST, GET_OUR_PARTNERS_REQUEST, GET_PROPERTIES_BY_FILTER_REQUEST, GET_ALL_PROJECTS_REQUEST } from '../constants'
import {fetchHotProperties,fetchPopularProjects,fetchOurServices,fetchOurAgents,fetchTestimonials,fetchOurPartners, fetchPropertiesByFilter, fetchAllProjects} from './fetchLandingData'

function* LandingSaga() {
    yield takeLatest(GET_HOT_PROPERTIES_REQUEST, fetchHotProperties);   
    yield takeLatest(GET_POPULAR_PROJECTS_REQUEST, fetchPopularProjects);
    yield takeLatest(GET_ALL_PROJECTS_REQUEST,fetchAllProjects);
    yield takeLatest(GET_OUR_SERVICES_REQUEST,fetchOurServices);
    yield takeLatest(GET_OUR_AGENTS_REQUEST,fetchOurAgents);
    yield takeLatest(GET_TESTIMONIALS_REQUEST,fetchTestimonials);
    yield takeLatest(GET_OUR_PARTNERS_REQUEST,fetchOurPartners);
    yield takeLatest(GET_PROPERTIES_BY_FILTER_REQUEST,fetchPropertiesByFilter);
}

export default LandingSaga