import * as actionTypes from './constants'
export const fetchHotPropertyRequest = data => ({
    type: actionTypes.GET_HOT_PROPERTIES_REQUEST,
    payload: data
});
export const fetchHotPropertySuccess = data => ({
    type: actionTypes.GET_HOT_PROPERTIES_SUCCESS,
    payload: data
});
  
export const fetchHotPropertyFailure = data => ({
    type: actionTypes.GET_HOT_PROPERTIES_FAILURE,
    payload: data
});

export const fetchPopularProjectsRequest = data => ({
    type: actionTypes.GET_POPULAR_PROJECTS_REQUEST,
    payload: data
});

export const fetchPopularProjectsSuccess = data => ({
    type: actionTypes.GET_POPULAR_PROJECTS_SUCCESS,
    payload: data
});

export const fetchPopularProjectsFailure = data => ({
    type: actionTypes.GET_POPULAR_PROJECTS_SUCCESS,
    payload: data
});

export const fetchAllProjectsRequest = data => ({
    type: actionTypes.GET_ALL_PROJECTS_REQUEST,
    payload : data
});
export const fetchAllCustomersRequest = data => ({
    type: actionTypes.GET_ALL_CUSTOMERS_REQUEST,
    payload : data
})
export const fetchAllProjectsSuccess = data => ({
    type: actionTypes.GET_ALL_PROJECTS_SUCCESS,
    payload : data
});
export const fetchAllProjectsFailure = data => ({
    type: actionTypes.GET_ALL_PROJECTS_FAILURE,
    payload : data
});

export const fetchOurServicesRequest = data => ({
    type: actionTypes.GET_OUR_SERVICES_REQUEST,
    payload: data
});

export const fetchOurServicesSuccess = data => ({
    type: actionTypes.GET_OUR_SERVICES_SUCCESS,
    payload: data
});

export const fetchOurServicesFailure = data => ({
    type: actionTypes.GET_OUR_SERVICES_FAILURE,
    payload: data
});

export const fetchOurAgentsRequest = data => ({
    type: actionTypes.GET_OUR_AGENTS_REQUEST,
    payload: data
});

export const fetchOurAgentsSuccess = data => ({
    type: actionTypes.GET_OUR_AGENTS_SUCCESS,
    payload: data
});

export const fetchOurAgentsFailure = data => ({
    type: actionTypes.GET_OUR_AGENTS_FAILURE,
    payload: data
});

export const fetchTestimonialsRequest = data => ({ 
    type: actionTypes.GET_TESTIMONIALS_REQUEST,
    payload: data
});

export const fetchTestimonialsSuccess = data => ({ 
    type: actionTypes.GET_TESTIMONIALS_SUCCESS,
    payload: data
});

export const fetchTestimonialsFailure = data => ({ 
    type: actionTypes.GET_TESTIMONIALS_FAILURE,
    payload: data
});

export const fetchOurPartnersRequest = data => ({ 
    type: actionTypes.GET_OUR_PARTNERS_REQUEST,
    payload: data
});

export const fetchOurPartnersSuccess = data => ({ 
    type: actionTypes.GET_OUR_PARTNERS_SUCCESS,
    payload: data
});

export const fetchOurPartnersFailure = data => ({ 
    type: actionTypes.GET_OUR_PARTNERS_FAILURE,
    payload: data
});

export const setSelectedOptionsRequest = data => ({
    type: actionTypes.SET_SELECTED_OPTIONS_REQUEST,
    payload: data
});

export const fetchPropertiesByFilterRequest = data => ({
    type: actionTypes.GET_PROPERTIES_BY_FILTER_REQUEST,
    payload: data
});

export const fetchPropertiesByFilterSuccess = data => ({
    type: actionTypes.GET_PROPERTIES_BY_FILTER_SUCCESS,
    payload: data
});

export const fetchPropertiesByFilterFailure = data => ({
    type: actionTypes.GET_PROPERTIES_BY__FILTER_FAILURE,
    payload: data
});