import React, { useEffect, useRef, useState } from 'react';
import request from '../../service/request';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import _ from "lodash";
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import nopropertyfoundimg from "../../rels_assets/images/property/no-propertyfound.png";
import { fetchAllCustomersRequest } from '../Landing/actions';
import $, { data } from 'jquery';
import NewPagination from '../../NewPagination';

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

const CustomerList = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const customLocation = useLocation();
    const [pageData, setPageData] = useState([]);
    const [pageNotExist, setPageNotExist] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const pageslug = 'customer-list';
    const [menu, setMenu] = useState([]);
    const [settings, setSetting] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [totalSearch, setTotalSearch] = useState('');
    const [pagingMeta, setPagingMeta] = useState(false);
    const [bannerImage, setBannerImage] = useState("");
    const pageName = history.location.pathname.split('/').map((i) => _.startCase(_.camelCase(i))).join(' / ');
    useEffect(() => {
        scrollToTop();
        getPageData();
    },[]);

    function SkeletonCustom(props) {
        return (
            <>
                <div className={`  ${props.parentClassName}`} >
                    <Skeleton className={props.classes} width={props.width} height={props.height} inline={props.inline} count={props.count} style={{ height: "200px", width: "350px", margin: "10px" }} />
                </div>
            </>
        );
    }

    const getPageData = () => {
        request({
            method: 'post',
            url: 'pages/get-page-by-slug',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
            data: { 'slug': pageslug }
        })
            .then((response) => {
                setPageData(response.data);
            })
            .catch(({ data }) => {
                setPageNotExist(true);
            });
    };
    useEffect(() => {
        scrollToTop();
        request({
            method: 'get',
            url: '/get-menu',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
        })
            .then((response) => {
                setMenu(response?.data);
            })
            .catch(({ data }) => ({ error: data }));
            getPageData();
    }, []);
    const getCustomersData = (page) => {
        try{
            request({
                method: 'get',
                url: `/get-customers?page=${page}`,
                headers: { "Content-Type": "application/json", "Accept": "application/json" },
            }).then((response) => {
                setCustomers(response.data.data);
                setPagingMeta(response.data.meta);
                setIsLoading(false);
            })
        }catch(error){
            console.error('Error Fetching Customers:', error);
            setIsLoading(false);
        }
    }

    /* useEffect(() => {
        const getCustomersData = async (page) => {
            try {
                setIsLoading(true);
                const response = await request({
                    method: 'get',
                    url: `/get-customers?page=${page}`,
                    headers: { "Content-Type": "application/json", "Accept": "application/json" },
                });

                if (response && response.data && Object.keys(response.data).length > 0) {
                    setCustomers(response.data.data);
                    setPagingMeta(response.data.meta); // Assuming response.data.meta contains pagination metadata
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Error fetching customers:', error);
                setIsLoading(false);
            }
        };
        getCustomersData(currentPage);
    }, [currentPage]); */

    useEffect(() => {
        if (menu && menu.code === 0) {
            setSetting(menu.data.site_setting);
        }
        setBannerImage((pageData?.data?.banner_image) ? pageData?.data?.banner_image : '');
        getCustomersData();
    }, [menu]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>

            <div>
                <Helmet defer={false}
                    bodyAttributes={{
                        class: ((customLocation.pathname == "/")) ? 'homepage-9 hp-6 homepage-11 mh' : 'inner-pages homepage-4 homepage-1 agents hp-6 full hd-white about'
                    }}>
                    <title>{(pageData && pageData?.data && Object.keys(pageData?.data).length > 0) ? `${menu.data?.site_setting?.site_name} - ${pageData?.data.meta_title}` : "About Us"}</title>
                    <meta name="description" content={pageData && pageData?.data && Object.keys(pageData?.data).length > 0 && pageData?.data.meta_description} />
                    {
                        pageData?.data &&
                        <script type="application/ld+json">
                            {JSON.stringify({
                                "@context": "https://schema.org/",
                                "@type": "Website",
                                "name": pageData?.data.meta_title,
                                "description": pageData?.data.meta_description,
                            })}
                        </script>
                    }
                </Helmet>
            </div>


            <main id="main">
                <section className='headings' style={{ background: `transparent` }} >
                    <div className='text-heading text-right'>
                        <div className='container'>
                            <h1 style={{
                                backgroundImage: `url('${bannerImage}')`,
                                backgroundImage: `-webkit-image-set(url('${bannerImage}') 1x, url('${bannerImage}') 2x)`,
                                backgroundImage: `image-set(url('${bannerImage}') 1x, url('${bannerImage}') 2x)`,
                                backgroundSize: "contain, cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "left center", height: "23vh", padding: "7rem 0", margin: "2rem 0"
                            }}>{pageData?.data?.name}</h1>
                        </div>
                    </div>
                </section>

                {
                    pageData?.data?.page_description &&
                    <section className='about-us fh'>
                        <div className='container' dangerouslySetInnerHTML={{ __html: pageData?.data?.page_description }} ></div>
                    </section>
                }

                {
                    customers && customers.length !== 0 ?
                        <>
                            <section className="properties-right featured portfolio bg-transparent blog pt-5">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 blog-pots">
                                            <div className="row">
                                                {customers.map((cus) => (
                                                    <div key={cus.id} className="item col-lg-4 col-md-6 col-xs-12 landscapes sale">
                                                        <div className="project-single customer-card-height">
                                                            <div className="project-inner project-head">
                                                                <div className="homes">
                                                                    <img
                                                                        src={cus.logo}
                                                                        alt="customer-logo"
                                                                        className="img-responsive"
                                                                        loading='lazy'
                                                                        onError={(e) => {
                                                                            e.target.onerror = null;
                                                                            e.target.src = nopropertyfoundimg;
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="homes-content">
                                                                <div className="the-agents">
                                                                    <h3 className='text-truncate' title={cus.company}>
                                                                        {cus.company?.length > 0 ? cus.company : <span>N/A</span>}
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {pagingMeta && (
                                        <NewPagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={pagingMeta.last_page}
                                            pageSize={1}
                                            onPageChange={handlePageChange}
                                        />
                                    )}
                                </div>
                            </section>
                        </>
                        :
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-3 text-center'>
                                    <h4 className='text-center fs-3'>No Customer Found!</h4>
                                </div>
                            </div>
                        </div>
                }
            </main>
        </>
    );
}
export default CustomerList;