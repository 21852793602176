import * as actionTypes from './constants'

function CommonReducers(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.GET_MENU_REQUEST:
            return {
                ...state,
                isMenuLoading: true
            };
        case actionTypes.GET_MENU_SUCCESS:
            return {
                
                isMenuLoading: false,
                menu: payload
            };
        case actionTypes.GET_MENU_FAILURE:
            return {
                ...state,
                isMenuLoading: false,
                error: payload
            };
        case actionTypes.TOGGLE_MENU_REQUEST:            
            return {
                ...state,
                toggleMenu: !state.toggleMenu
            };
        case actionTypes.FETCH_SEARCH_OPTIONS_REQUEST:
            return {
                ...state,
                isSeachOptionsLoading: true
            };
        case actionTypes.FETCH_SEARCH_OPTIONS_SUCCESS:
            return {
                ...state,
                isSeachOptionsLoading: false,
                searchOptions: payload
            };
        case actionTypes.FETCH_SEARCH_OPTIONS_FAILURE:
            return {
                ...state,
                isSeachOptionsLoading: false
            };
        case actionTypes.SET_MEASUREMENT_LABEL_REQUEST:            
            return {
                ...state,
                measurement_lable:payload
            };
        default:
            return state
    }
}
export default CommonReducers
