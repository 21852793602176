import React, { useEffect, useState } from 'react'
import img from "../../rels_assets/images/testimonials/ts-1.jpg";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from 'react-redux';
import { fetchTestimonialsRequest } from '../Landing/actions';
import $, { data } from 'jquery';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Testimonial = () => {
    const options = {
        items: 3,
        nav: true,
        dots: false,
        rewind: true,
        autoplay: false,
        margin: 30,
        width: 450,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
            },
            574: {
                items: 1,
            },
            768: {
                items: 1,
            },
            991: {
                items: 1,
            },
            1200: {
                items: 3,
            },
            1920: {
                items: 3,
            },
        }
    };

    const dispatch = useDispatch();
    const [ourtestimonials, setTestimonials] = useState([]);
    const testimonialData = useSelector(store => store.landingPageData.testimonials.length > 0 ? store.landingPageData.testimonials : store.landingPageData.testimonials)
    useEffect(() => {
        loadData();
    }, []);
    useEffect(() => {
        if (typeof testimonialData === 'object' && Object.keys(testimonialData).length > 0) {
            setTestimonials(testimonialData.data);
        }
    }, [testimonialData]);
    useEffect(() => {
    }, [ourtestimonials]);
    function loadData() {
        dispatch(fetchTestimonialsRequest(JSON.stringify([data])));
    }
    return (
        <>
            {/* <!-- START SECTION TESTIMONIALS --> */}
            {
                ourtestimonials && ourtestimonials.length > 0 &&

                <section className="testimonials bg-white-2 rec-pro">
                    <div className="container-fluid aboutus-container-fluid">
                        <div className="sec-title">
                            <h2><span>Clients </span>Testimonials</h2>
                            {/* <p>We collect reviews from our customers.</p> */}
                        </div>
                        {/* <div className=""> */}
                        {ourtestimonials.length > 0 && (
                            <OwlCarousel className='job_clientSlide owl-theme' {...options}>
                                {ourtestimonials.map((item) => (
                                    <div className="singleJobClinet aos-init" key={`testimonials-${item.id}`} data-aos="zoom-in" data-aos-delay="150">
                                        <p dangerouslySetInnerHTML={{ __html: item.testimonial_description }}></p>
                                        <div className="detailJC">
                                            <span><img loading='lazy' src={item.image ? item.image : img} alt="" /></span>
                                            <h5>{item.name}</h5>
                                            <p>{item?.city}</p>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        )}
                        {/* </div> */}
                    </div>
                </section>
            }

            {/* <!-- END SECTION TESTIMONIALS --> */}
        </>
    )
}

export default Testimonial
