import { combineReducers } from 'redux';
import LandingReducers from '../components/Landing/reducers'
import LoginSignupReducer from '../components/LoginSignup/reducer'
import sysMessage from '../components/notifications/system/sysMessageReducer'
import InquiryReducer from '../components/Inquiry/reducer'
import CommonReducers from '../components/common/commonReducers'

const reducer = combineReducers({
    landingPageData: LandingReducers,  
    commonPageData: CommonReducers,
    loginSignupPageData: LoginSignupReducer,   
    InquiryModalData: InquiryReducer,
    sysMessage
})

export default reducer