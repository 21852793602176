import React, { useEffect, useState } from "react";
import img from "../../rels_assets/images/logo-footer.svg";
import request from '../../service/request';
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addSysMessage } from "../notifications/system/sysMessageAction";
import { SYS_MESSAGE_TYPE_SUCCESS } from "../notifications/system/components/Message";
import { useHistory } from "react-router-dom"
import _ from "lodash";

const Footer = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [menu, setMenu] = useState([]);
    const [settings, setSetting] = useState([]);
    const [defaultPages, setDefaultPage] = useState([]);
    const [otherPages, setOtherPage] = useState([]);
    const [subscriptionMsg, setSubscriptionMsg] = useState("");
    const [city, setCity] = useState();
    const [zipCode, setZipCode] = useState();
    const [isLoading, setIsLoading] = useState(false);
    /*** NewsLetter form initial value.. */
    const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
    const newsLetterSchema = Yup.object().shape({
        first_name: Yup.string().min(2).max(25).required("Please enter your full name"),
        phone: Yup.string().optional().matches(phoneRegExp, 'Phone number is not valid'),
        email: Yup.string().email().required("Please enter your email"),
    });

    const forminitialValues = {
        first_name: "",
        phone: "",
        email: "",
    };


    const { values, handleBlur, handleChange, handleSubmit, errors, touched, isValid } =
        useFormik({
            initialValues: forminitialValues,
            validationSchema: newsLetterSchema,
            //// By disabling validation onChange and onBlur formik will validate on submit.
            onSubmit: (values, action) => {
                //// to get rid of all the values after submitting the form
                setIsLoading(true);
                subscribeRequest(values);
                action.resetForm();
            },
        });


    useEffect(() => {
        request({
            method: 'get',
            url: '/get-menu',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
        })
            .then((response) => {
                setMenu(response?.data);
            })
            .catch(({ data }) => ({ error: data}));
    }, []);




    useEffect(() => {
        if (menu && menu.code == 0) {
            setSetting(menu.data.site_setting);
            setDefaultPage(menu.data.pages.default_pages)
            setOtherPage(menu.data.pages.other_pages)
            setCity(menu.data.site_setting.city)
            setZipCode(menu.data.site_setting.zipcode)
        }
    }, [menu]);

    function subscribeRequest(formValues, actions) {
        setIsLoading(true);
        request({
            method: 'post',
            url: '/newsletter/save',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
            data: formValues
        })
            .then((response) => {
                setSubscriptionMsg(response?.data?.message);
                // dispatch(addSysMessage(response?.data?.message, SYS_MESSAGE_TYPE_SUCCESS));
                actions.resetForm();
            })
            .catch(({ data }) => {
                Object.keys(data.errors).map((index, item) => {
                    actions.setFieldError(index, _.get(data.errors, `${index}[0]`));
                });
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false); // Stop loading state after request completes
            });
    }

    return (
        <>
            <footer className="first-footer rec-pro">
                <div className="top-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="netabout">
                                    <Link to={`/`} className="footer-logo" aria-label="footer-logo">
                                        <img loading="lazy" src={((settings && settings.icon_logo && settings.icon_logo.length) ? settings.icon_logo : img)} alt="netcom" />
                                    </Link>
                                    {/* <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Cum incidunt
                                        architecto soluta laboriosam,
                                        perspiciatis, aspernatur officiis esse.
                                    </p> */}
                                </div>
                                <div className="contactus">
                                    <ul>
                                        {settings && settings.address &&
                                            <li>
                                                <div className="info">
                                                    <i
                                                        className="fa fa-map-marker"
                                                        aria-hidden="true"
                                                    ></i>
                                                    <p className="in-p">
                                                        {settings.address.length && settings.address} {city} - {zipCode}
                                                    </p>
                                                </div>
                                            </li>
                                        }
                                        {settings && settings.mobile &&
                                            <li>
                                                <div className="info">
                                                    <i
                                                        className="fa fa-phone"
                                                        aria-hidden="true"
                                                    ></i>
                                                    <a href={`tel:${settings.mobile}`} aria-label="mobile">
                                                        <p className="in-p">
                                                            {settings.mobile.length && settings.mobile}
                                                        </p>
                                                    </a>
                                                </div>
                                            </li>
                                        }
                                        {settings && settings.email &&
                                            <li>
                                                <div className="info">
                                                    <i
                                                        className="fa fa-envelope"
                                                        aria-hidden="true"
                                                    ></i>
                                                    <a href={`mailto:${settings.email}`} aria-label="email">
                                                        <p className="in-p ti">
                                                            {settings.email.length && settings.email}
                                                        </p>
                                                    </a>
                                                </div>
                                            </li>
                                        }
                                    </ul>
                                </div>
                                <ul className="netsocials mt-3">
                                    <li>
                                        {
                                            settings?.facebook_url &&
                                            <a href={settings.facebook_url} target="_blank" aria-label="">
                                                <i className="fa fa-facebook" aria-hidden="true"></i>
                                            </a>
                                        }
                                    </li>
                                    <li>
                                        {
                                            settings?.twitter_url &&
                                            <a href={settings.twitter_url} target="_blank" aria-label="">
                                                <i
                                                    className="fa fa-twitter"
                                                    aria-hidden="true"
                                                ></i>
                                            </a>
                                        }
                                    </li>
                                    <li>
                                        {
                                            settings?.instagram_url &&
                                            <a href={settings.instagram_url} target="_blank" aria-label="">
                                                <i className="fa fa-instagram"></i>
                                            </a>
                                        }
                                    </li>
                                    <li>
                                        {
                                            settings?.youtube_url &&
                                            <a href={settings.youtube_url} target="_blank" aria-label="">
                                                <i
                                                    className="fa fa-youtube"
                                                    aria-hidden="true"
                                                ></i>
                                            </a>
                                        }
                                    </li>
                                    <li>
                                        {
                                            settings?.linkedin_url &&
                                            <a href={settings.linkedin_url} target="_blank" aria-label="">
                                                <i
                                                    className="fa fa-linkedin text-white ml-3"
                                                    aria-hidden="true"
                                                ></i>
                                            </a>
                                        }
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                {(defaultPages.length > 0 || otherPages.length > 0) &&
                                    <div className="navigation">
                                        <h3>Navigation</h3>
                                        <div className="nav-footer">
                                            <ul className="row">
                                                {defaultPages && defaultPages.length > 0 && defaultPages.map(page => {
                                                    return (
                                                        (page.show_page_in == 'on_footer_menu' || page.show_page_in == 'on_both') &&
                                                        <div className="col-6">
                                                            <li key={`defaultpages-footer-${page.slug}`}>
                                                                <Link to={`/page/${page.slug}`} aria-label="">{page.name}</Link>
                                                                {/* <a href="about.html">
                                                                {page.name}
                                                            </a> */}
                                                            </li>
                                                        </div>
                                                    )
                                                })}
                                                {otherPages && otherPages.length > 0 && otherPages.map(page => {
                                                    return (
                                                        (page.show_page_in == 'on_footer_menu' || page.show_page_in == 'on_both') &&
                                                        <div className="col-6">
                                                            <li key={`otherpages-footer-${page.slug}`}>
                                                                <Link to={`/page/${page.slug}`} aria-label="">{page.name}</Link>
                                                                {/* <a href="about.html">
                                                                {page.name}
                                                            </a> */}
                                                            </li>
                                                        </div>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-3">
                                <div className="newsletters">
                                    <h3>Newsletters</h3>
                                    <p>
                                        Sign Up for Our Newsletter to get Latest
                                        Updates and Offers. Subscribe to receive
                                        news in your inbox.
                                    </p>
                                </div>
                                <form
                                    className="bloq-email mailchimp form-inline d-inline-block"
                                    method="post" onSubmit={handleSubmit}>
                                    <div className="txtName row my-2">
                                        {/* <div className=""> */}
                                        <div className="col-6 px-1">
                                            <input
                                                className="w-100"
                                                type="text"
                                                id={values.id}
                                                name="first_name"
                                                value={values.first_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Full Name"
                                            />
                                            {errors.first_name && touched.first_name ? (<div><span className="text-danger text-wrap ">{errors.first_name}</span></div>) : " "}
                                        </div>
                                        <div className="col-6 px-1">
                                            <input className="w-100"
                                                type="text"
                                                id={values.id}
                                                name="phone"
                                                value={values.phone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder=" Mobile"
                                            />
                                            {errors.phone && touched.phone ? (<div><span className="text-danger text-wrap">{errors.phone}</span></div>) : " "}
                                        </div>
                                        {/* </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-12 px-1">
                                            <div className="email">
                                                <input className="col-lg-7 col-md-6 col-sm-3"
                                                    type="email"
                                                    id={values.id}
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Email"
                                                />
                                                {/* <input className="col-lg-5 col-md-6 col-sm-3"
                                                    type="submit"
                                                    value='Subscribe'
                                                /> */}
                                                <button type="submit" id="submit-contact" class="btn btn-lg text-white" style={{width: "155px",borderRadius: 'unset'}} disabled={isLoading}>{isLoading ? (<Loader />) : 'Subscribe'} </button>
                                            </div>
                                            {errors.email && touched.email ? (<span className="text-danger ">{errors.email}</span>) : " "}
                                            <p className="subscription-success text-white mt-3 h3">{subscriptionMsg}</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="second-footer rec-pro">
                    <div className="container-fluid sd-f">
                        <p><strong>Copyright © 2023 <span className="copy-rights-text">{settings.name}</span></strong>&nbsp;All rights reserved.</p>
                        {
                            settings && settings.rera_no ? <p className="text-end powered-by"><strong>RERA No: <span className="copy-rights-text">{settings.rera_no}</span></strong>&nbsp;</p> : ''
                        }
                    </div>
                </div>
            </footer>

            {/* <div id="preloader">
                <div id="status">
                    <div className="status-mes"></div>
                </div>
            </div> */}
        </>
    );
}
function Loader() {
    return (
        <div className="spinner-border text-white" role="status">
            <div className="sr-only">Loading...</div>
        </div>
    );
}

export default Footer;