import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';



export const SYS_MESSAGE_TYPE_ERROR = 'error';
export const SYS_MESSAGE_TYPE_SUCCESS = 'success';
export const SYS_MESSAGE_TYPE_INFO = 'info';
const propTypes = {
  removeMessage: PropTypes.func.isRequired,
  messageType: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

class Message extends Component {
  removeMesage = () => {
    this.props.removeMessage();
  };

  render() {    
    const { message, messageType, positionIdx } = this.props;
    const alertClassNames = classnames('alert', {
      'alert-danger': messageType === SYS_MESSAGE_TYPE_ERROR,
      'alert-success': messageType === SYS_MESSAGE_TYPE_SUCCESS,
      'alert-info': messageType === SYS_MESSAGE_TYPE_INFO
    });
    return this.props.positionIdx === 0 ? (
      <Snackbar
        open={true}
        autoHideDuration={6000}
        onClose={this.removeMesage}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}       
        style={{ zIndex: '999999'  }}
      >
        <SnackbarContent className={alertClassNames} message={message} />
      </Snackbar>
    ) : (
      ''
    );
  }
}
Message.propTypes = propTypes;

export { Message as MessageTest };

export default Message;
