import { call, put } from 'redux-saga/effects';
import { getHotProperties,getPopularProjects,getOurServices,getOurAgents,getTestimonials,getOurPartners,getPropertiesByFilter, getAllProjects } from '../landingService'
import * as actionTypes from '../actions'
import request from '../../../service/request';

export function* fetchHotProperties(action) {
  const { payload } = action;
  const { data } = yield call(getHotProperties, payload);
  if (data) {
    yield put(actionTypes.fetchHotPropertySuccess(data));
  } else {
    yield put(actionTypes.fetchHotPropertyFailure());
  }
}

export function* fetchPopularProjects(action){
  const { payload } = action;
  const { data } = yield call(getPopularProjects, payload);
  if(data){
    yield put(actionTypes.fetchPopularProjectsSuccess(data));
  }else{
    yield put(actionTypes.fetchPopularProjectsFailure());
  }
}

export function* fetchAllProjects(action){
  const { payload } = action;
  const { data } = yield call(getAllProjects, payload);
  if(data){
    yield put(actionTypes.fetchAllProjectsSuccess(data));
  }else{
    yield put(actionTypes.fetchAllProjectsFailure());
  }
}

export function* fetchOurServices(action){
  const { payload } = action;
  const { data } = yield call(getOurServices, payload);
  if(data){
    yield put(actionTypes.fetchOurServicesSuccess(data));
  }else{
    yield put(actionTypes.fetchOurServicesFailure());
  }
}

export function* fetchOurAgents(action){
  const { payload } = action;
  const { data } = yield call(getOurAgents, payload);
  if(data){
    yield put(actionTypes.fetchOurAgentsSuccess(data));
  }else{
    yield put(actionTypes.fetchOurAgentsFailure());
  }
}

export function* fetchTestimonials(action){
  const { payload } = action;
  const { data } = yield call(getTestimonials, payload);
  if(data){
    yield put(actionTypes.fetchTestimonialsSuccess(data));
  }else{
    yield put(actionTypes.fetchTestimonialsFailure());
  }
}

export function* fetchOurPartners(action){
  const { payload } = action;
  const { data } = yield call(getOurPartners, payload);
  if(data){
    yield put(actionTypes.fetchOurPartnersSuccess(data));
  }else{
    yield put(actionTypes.fetchOurPartnersFailure());
  }
}

export function* fetchPropertiesByFilter(action){
  const {payload} = action;
  const {data} = yield call(getPropertiesByFilter,payload);
  if(data){
    yield put(actionTypes.fetchPropertiesByFilterSuccess(data));
  }else{
    yield put(actionTypes.fetchPropertiesByFilterFailure());
  }
}