import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchOurPartnersRequest } from '../Landing/actions';
import $, { data } from 'jquery';
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import defaultimage from "../../rels_assets/images/RealEstatePaerner150x100.svg";

const OurPartner = () => {
    const options = {
        items: 3,
        nav: false,
        dots: false,
        lazyLoad: true,
        rewind: true,
        autoplay: false,
        // loop: true,
        autoplaySpeed: 200,
        smartSpeed: 100,
        // margin: 20,
        autoplayHoverPause: false,
        width: 300.143,
        autoWidth: true,
        mergeFit: true,
        responsiveClass: true,
        // center: true,
        responsive: {
            0: {
                items: 2,
            },
            574: {
                items: 2,
            },
            768: {
                items: 4,
            },
            991: {
                items: 6,
            },
            1200: {
                items: 4,
            },
            1920: {
                items: 7,
            },
        }
    };

    const dispatch = useDispatch();
    const [ourpartners, setPartners] = useState([]);
    const partnerData = useSelector(store => store.landingPageData.partners.length > 0 ? store.landingPageData.partners : store.landingPageData.partners)
    useEffect(() => {
        loadData()
    }, []);
    useEffect(() => {
        if (typeof partnerData === 'object' && Object.keys(partnerData).length > 0) {
            setPartners(partnerData.data);
        }
    }, [partnerData]);
    function loadData() {
        dispatch(fetchOurPartnersRequest(JSON.stringify([data])));
    }
    return (
        <>
            {/* <!-- STAR SECTION PARTNERS --> */}

            {
                ourpartners && ourpartners.length > 0 &&

                <div className="partners bg-white rec-pro">
                    <div className="container-fluid aboutus-container-fluid">
                        <div className="sec-title">
                            <h2><span>Our </span>Partners</h2>
                            {/* <p>The Companies That Represent Us.</p> */}
                        </div>
                        {ourpartners.length > 0 && (
                            <OwlCarousel className='style2 d-flex row justify-content-center' {...options}>
                                {ourpartners.map((item) => (
                                    <div key={`ourpatners-${item.id}`} data-aos="fade-up" style={{ width: "150px", height: "100px", marginRight: "30px" }}>
                                        <img loading='lazy' className='align-items-center' height={100} width={150} style={{ width: "100%", height: "100%", objectFit: "contain" }} src={item.image ? item.image : defaultimage} alt={item.website} title={item.website} onError={(e) => (
                                            e.target.onerror = null,
                                            e.target.src = defaultimage
                                        )} />
                                        {/* <span>{item.website}</span> */}
                                    </div>
                                ))}
                            </OwlCarousel>
                        )}
                    </div>
                </div>
            }
            {/* <!-- END SECTION PARTNERS -->  */}
        </>
    )
}

export default OurPartner
