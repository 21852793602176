import * as actionTypes from './constants'

function LoginReducer(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.VALIDATE_LOGIN_REQUEST:
            return {
                ...state,
                otpVerificationLoading: true
            }
        case actionTypes.VALIDATE_LOGIN_FALIED:
            return {
                ...state,
                isLoginSuccess: false,
                otpVerificationLoading: false
            }
        case actionTypes.VALIDATE_LOGIN_SUCCESS:
            return {
                ...state,
                otpVerificationLoading: false,
                isLoginSuccess: payload.length > 0 ? (payload[0].status === 'true' ? true : false) : false,
                loginData: payload
            }
        case action.UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                otpVerificationLoading: true
            }
        case action.UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                otpVerificationLoading: false
            }
        case action.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                otpVerificationLoading: false,
                loginData: payload
            }
        case actionTypes.USER_SIGNUP_REQUEST:
            return {
                ...state,
                otpVerificationLoading: true
            }
        case actionTypes.USER_SIGNUP_SUCCESS:
            return {
                ...state,
                isOtpSent: payload.length > 0 ? (payload[0].status === 'true' ? true : false) : false,
                signUpData: payload,
                loginData:payload,
                isLoginSuccess:true,
                otpVerificationLoading: false
            }
        case actionTypes.USER_SIGNUP_FAILURE:
            return {
                ...state,
                otpVerificationLoading: false,
                signUpData:payload
            }
        case actionTypes.OTP_VERIFICATION_REQUEST:
            return {
                ...state,
                otpVerificationLoading: true
            }
        case actionTypes.OTP_VERIFICATION_SUCCESS:
            return {
                ...state,
                otpVerificationLoading: false,
                isLoginSuccess: payload.length > 0 ? (payload[0].status === 'true' ? true : false) : false,
                loginData: payload
            }
        case actionTypes.OTP_VERIFICATION_FAILURE:
            return {
                ...state,
                otpVerificationLoading: false
            }
        case actionTypes.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                changePasswordLoading: true,
                otpVerificationLoading: true,
            }
        case actionTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordData: payload,
                changePasswordLoading: false,
                otpVerificationLoading: false,
            }
        case actionTypes.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePasswordLoading: false,
                otpVerificationLoading: false,
            }
        case actionTypes.FORGOT_PSWD_OTP_REQUEST:
            return {
                ...state,
                otpVerificationLoading: true
            }
        case actionTypes.FORGOT_PSWD_OTP_SUCCESS:
            return {
                ...state,
                otpVerificationLoading: false
            }
        case actionTypes.FORGOT_PSWD_OTP_FAILURE:
            return {
                ...state,
                otpVerificationLoading: false
            }
        case actionTypes.TOGGLE_LOGIN_MODAL:
            return {
                ...state,
                isVisible: payload.isVisible,
                modalForm:payload.modalForm
            }
        default:
            return state;
    }
}
export default LoginReducer
