import * as actionTypes from './constants'

function LandingReducers(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.GET_HOT_PROPERTIES_REQUEST:
            return {
                ...state,
                isHotPropertiesLoading: true
            };
        case actionTypes.GET_HOT_PROPERTIES_SUCCESS:
            return {
                ...state,
                isHotPropertiesLoading: false,
                hotPropertyData: payload
            };
        case actionTypes.GET_HOT_PROPERTIES_FAILURE:
            return {
                ...state,
                isHotPropertiesLoading: false
            };        
        case actionTypes.GET_POPULAR_PROJECTS_REQUEST:
            return {
              ...state,
                popularProjectsLoading: true
            };
        case actionTypes.GET_POPULAR_PROJECTS_SUCCESS:
            return {
               ...state,
                popularProjectsLoading: false,
                popularProjects: payload
            };
        case actionTypes.GET_POPULAR_PROJECTS_FAILURE:
            return {
                ...state,
                popularProjectsLoading: false,
                popularProjects: []
            };
        case actionTypes.GET_ALL_PROJECTS_REQUEST:
            return {
                ...state,
                allProjectsLoading: true,
            };
        case actionTypes.GET_ALL_PROJECTS_SUCCESS:
            return {
                ...state,
                allProjectsLoading: false,
                allProjects: payload
            };
        case actionTypes.GET_ALL_PROJECTS_FAILURE:
            return {
                ...state,
                allProjectsLoading: false,
                allProjects:[]
            };
        case actionTypes.GET_OUR_SERVICES_REQUEST:
            return {
              ...state,
                ourServicesLoading: true
            };
        case actionTypes.GET_OUR_SERVICES_SUCCESS:
            return {
              ...state,
                ourServicesLoading: false,
                ourServices: payload
            };
        case actionTypes.GET_OUR_SERVICES_FAILURE:
            return {
                ...state,
                ourServicesLoading: false,
                ourServices: []
            };
        case actionTypes.GET_OUR_AGENTS_REQUEST:
            return {
              ...state,
                ourAgentsLoading: true
            };
        case actionTypes.GET_OUR_AGENTS_SUCCESS:
            return {
              ...state,
                ourAgentsLoading: false,
                ourAgents: payload
            };
        case actionTypes.GET_OUR_AGENTS_FAILURE:
            return {
               ...state,
                ourAgentsLoading: false,
                ourAgents: []
            };
        case actionTypes.GET_TESTIMONIALS_REQUEST:
            return {
             ...state,
             isTestimonialsLoading: true
            };
        case actionTypes.GET_TESTIMONIALS_SUCCESS:
            return {
              ...state,
              isTestimonialsLoading: false,
                testimonials: payload
            };
        case actionTypes.GET_TESTIMONIALS_FAILURE:
            return {
              ...state,
              isTestimonialsLoading: false,
                testimonials: []
            };
        case actionTypes.GET_OUR_PARTNERS_REQUEST:
            return {
            ...state,
             isPartnersLoading: true
            };
        case actionTypes.GET_OUR_PARTNERS_SUCCESS:
            return {
             ...state,
              isPartnersLoading: false,
                partners: payload
            };
        case actionTypes.GET_OUR_PARTNERS_FAILURE:
            return {
             ...state,
              isPartnersLoading: false,
                partners: []
            };
        /* case actionTypes.SET_SEARCH_OPTIONS_REQUEST:           
            return {
                ...state,
                selectedOptions: payload,
            }; */
        case actionTypes.SET_SELECTED_OPTIONS_REQUEST:
            return {
                ...state,
                selectedOptions: {...state.selectedOptions,...payload},
            };
        
        case actionTypes.GET_PROPERTIES_BY_FILTER_REQUEST:
            return {
                ...state,
                isPropertyListLoading: true,
                propertiesByFilter: payload
            };
        case actionTypes.GET_PROPERTIES_BY_FILTER_SUCCESS:
            return  {
                ...state,
                isPropertyListLoading: false,
                propertiesByFilter: payload
            }
        case actionTypes.GET_PROPERTIES_BY__FILTER_FAILURE:
            return {
                ...state,
                isPropertyListLoading: false,
                propertiesByFilter: []
            }
        default:
            return state
    }
}

export default LandingReducers