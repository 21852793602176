import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import OwlCarousel from "react-owl-carousel";
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import WhyChooseUs from '../why-choose-us/whyChooseUs';
import MeetOurAgent from '../meetOurAgents/meetOurAgent';
import Testimonial from '../testimonial/testimonial';
import OurPartner from '../our-partner/ourPartner';
import request from '../../service/request';

const scrollToTop = () => {
	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});
};
// import bgImg from './../rels_assets/images/bg/bg-video.jpg'

function PrivacyPolicy(props) {
	const customLocation = useLocation();
	const [pageData, setPageData] = useState([]);
	const [menu, setMenu] = useState([]);
	const [pageNotExist, setPageNotExist] = useState(false);
	const [bannerImage, setBannerImage] = useState("");
	const pageslug = 'privacy-policy';
	useEffect(() => {
		scrollToTop();
		getPageData();
	}, []);

	const getPageData = () => {
		request({
			method: 'post',
			url: 'pages/get-page-by-slug',
			headers: { "Content-Type": "application/json", "Accept": "application/json" },
			data: { 'slug': pageslug }
		})
			.then((response) => {
				setPageData(response.data);
			})
			.catch(({ data }) => {
				setPageNotExist(true);
			});
	}

	useEffect(() => {
		request({
			method: 'get',
			url: '/get-menu',
			headers: { "Content-Type": "application/json", "Accept": "application/json" },
		})
			.then((response) => {
				setMenu(response?.data);
			})
			.catch(({ data }) => ({ error: data }));
	}, []);


	const options = {
		items: 1,
		nav: false,
		dotsEach: 1,
		dots: true,
		// rewind: true,
		autoplay: false,
		// margin: 30,
		// width: 450

	};

	const options2 = {
		items: 3,
		center: true,
		nav: false,
		dots: false,
		rewind: false,
		autoplay: true,
		autoplaySpeed: 700,
		// margin: 10,
		width: 167.143
	};
	useEffect(() => {
		setBannerImage((pageData?.data?.banner_image) ? pageData?.data?.banner_image : '')
	}, [pageData]);

	return (
		<>
			<div>
				<HelmetProvider>
					<Helmet
						bodyAttributes={{
							class: ((customLocation.pathname == "/")) ? 'homepage-9 hp-6 homepage-11 mh' : 'inner-pages homepage-4 homepage-1 agents hp-6 full hd-white about'
						}}>
						<title>{(pageData && pageData?.data && Object.keys(pageData?.data).length > 0) ? `${menu.data?.site_setting?.site_name} - ${pageData?.data.meta_title}` : "Privacy Policy"}</title>
						<meta name="description" content={pageData && pageData?.data && Object.keys(pageData?.data).length > 0 && pageData?.data.meta_description} />
						{
							pageData?.data &&
							<script type="application/ld+json">
								{JSON.stringify({
									"@context": "https://schema.org/",
									"@type": "Website",
									"name": pageData?.data.meta_title,
									"description": pageData?.data.meta_description,
								})}
							</script>
						}
					</Helmet>
				</HelmetProvider>
			</div>
			<main id="main">
				<section className="small-space">
					<div className="container">
						<h1 className='text-right' style={{
							backgroundImage: `url('${bannerImage}')`,
							backgroundImage: `-webkit-image-set(url('${bannerImage}') 1x, url('${bannerImage}') 2x)`,
							backgroundImage: `image-set(url('${bannerImage}') 1x, url('${bannerImage}') 2x)`,
							backgroundSize: "contain, cover",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "left center", height: "35vh", padding: "7rem 0", margin: "2rem 0",
							fontSize: "26px",
						}}>Privacy Policy</h1>
						<div className="content-wrapper space-large">
							<div className="row">
								<div className='col-12'>
									{/* <h1 className="title1 custom-pages-title my-5"><strong>PRIVACY POLICY</strong></h1> */}
								</div>
								{
									pageData?.data?.page_description &&
									<div className="col-12" dangerouslySetInnerHTML={{ __html: pageData?.data?.page_description }} >
									</div>
								}
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
}

export default PrivacyPolicy;

