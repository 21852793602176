import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom"
import request from "../../service/request";
import { fetchPropertiesByFilterRequest, setSelectedOptionsRequest } from "../Landing/actions";
import { getQueryString } from "../../helper";
import _ from "lodash";


const PropertyByArea = () => {

    const allSelectedOption = useSelector(store => store.landingPageData.selectedOptions);
    const dispatch = useDispatch();
    const history = useHistory();
    const [area, setArea] = useState([]);
    const [city, setCity] = useState([]);
    const [setting, setSetting] = useState([]);


    function loadAreas() {
        request({
            method: 'get',
            url: '/get-property-area',
            data: {}
        }).then((response) => {
            setArea(response.data.data);
        });
    }

    useEffect(() => {
        loadAreas();
    }, [])

    const handleCity = (event, cityId, stateId) => {
        if (event.type === 'click') {
            allSelectedOption.city = cityId;
            allSelectedOption.state = stateId;
            let QueryString = "";
            dispatch(setSelectedOptionsRequest({ 'city': cityId, 'state': stateId }));
            console.log("State ID", stateId);
            if (typeof allSelectedOption === 'object' && Object.keys(allSelectedOption).length > 0) {
                // dispatch the properties list by selected filter options..
                QueryString = getQueryString(allSelectedOption);
                dispatch(fetchPropertiesByFilterRequest(JSON.stringify(allSelectedOption)))
            }
            history.push({
                pathname: '/all/property/city',
                search: `?${QueryString}`
            })
        }
    }

    const handleClick = (event, areaId, stateId, cityId) => {
        if (event.type === 'click') {
            allSelectedOption.area = areaId;
            allSelectedOption.state = stateId;
            allSelectedOption.city = cityId;
            let QueryString = "";
            dispatch(setSelectedOptionsRequest({ 'area': areaId, 'city': cityId, 'state': stateId }));
            if (typeof allSelectedOption === 'object' && Object.keys(allSelectedOption).length > 0) {
                QueryString = getQueryString(allSelectedOption);
                // dispatch the properties list by selected filter options..
                dispatch(fetchPropertiesByFilterRequest(JSON.stringify(allSelectedOption)))
            }
            history.push({
                pathname: '/all/property/area',
                search: `?${QueryString}`
            })
        }
    }
    return (
        <>
            <section className="how-it-works-area rec-pro">
                <div className="container ">
                    <div className="sec-title text-center">
                        {
                            Object.keys(area).length > 0 &&
                            area && area.length > 0 && area.map((areas) => {
                                {/* console.log(areas.areas) */ }
                                return (
                                    <>
                                        <div className="row">
                                            <div className="col-12 py-4">
                                                <h2 className="text-capitalize font-weight-normal"><a className="text-white footer-areas text-decoration-none" onClick={(event) => handleCity(event, areas.id, areas.state_id)}>Property in {areas.name}</a></h2>
                                            </div>
                                            {
                                                areas.areas && areas.areas.length > 0 && areas.areas.map((allAreas) => {
                                                    return (
                                                        <>
                                                            <div className="col-6 col-md-4 col-lg-3 py-2 text-left">
                                                                <a onClick={(event) => handleClick(event, allAreas.id, allAreas.state_id, allAreas.city_id)} className="text-white footer-areas text-decoration-none"> Property in {_.startCase(_.lowerCase(allAreas.area))}</a>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </>

                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default PropertyByArea;

