import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import _map from 'lodash/map';

import { removeSysMessage } from '../sysMessageAction';
import Message from './Message';



function SystemMessage({ msgInfo, removeSysMessage }) {
  return (
    <Fragment>
      {
        msgInfo !== undefined ?
        _map(msgInfo, (msg, key) => (
          <Message
            key={key}
            positionIdx={key}
            removeMessage={removeSysMessage}
            messageType={msg.messageType}
            message={msg.message}
          />
        )) : null
      }
     
    </Fragment>
  );
}


const mapStateToProps = state =>
 {
   return {
    msgInfo: state.sysMessage.msgInfo
   }
};

const mapDispatchToProps = dispatch => ({
  removeSysMessage: positionIdx => dispatch(removeSysMessage(positionIdx))
});

export { SystemMessage as SystemMessageTest };

export default connect(mapStateToProps, mapDispatchToProps)(SystemMessage);
