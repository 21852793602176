import * as actionTypes from './constants'

export const fetchMenuRequest = data => ({
    type: actionTypes.GET_MENU_REQUEST,
    payload: data
});

export const fetchMenuSuccess = data => ({
    type: actionTypes.GET_MENU_SUCCESS,
    payload: data
});

  
export const fetchMenuFailure = data => ({
    type: actionTypes.GET_MENU_FAILURE,
    payload: data
});

export const toggleMenuRequest = data => ({
    type: actionTypes.TOGGLE_MENU_REQUEST,
    payload: data
});

export const fetchSearchOptionsRequest = data => ({
     type: actionTypes.FETCH_SEARCH_OPTIONS_REQUEST,
     payload: data
});

export const fetchSearchOptionsSuccess = data => ({
    type: actionTypes.FETCH_SEARCH_OPTIONS_SUCCESS,
    payload: data
});

export const fetchSearchOptionsFailure = data => ({
    type: actionTypes.FETCH_SEARCH_OPTIONS_FAILURE,
    payload: data
});

export const setMeasurementLabelRequest = data => ({
    type: actionTypes.SET_MEASUREMENT_LABEL_REQUEST,
    payload: data
});