export const GET_MENU_REQUEST = 'GET_MENU_REQUEST'
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS'
export const GET_MENU_FAILURE = 'GET_MENU_FAILURE'

export const TOGGLE_MENU_REQUEST = 'TOGGLE_MENU_REQUEST'

export const FETCH_SEARCH_OPTIONS_REQUEST='FETCH_SEARCH_OPTIONS_REQUEST'
export const FETCH_SEARCH_OPTIONS_SUCCESS='FETCH_SEARCH_OPTIONS_SUCCESS'
export const FETCH_SEARCH_OPTIONS_FAILURE='FETCH_SEARCH_OPTIONS_FAILURE'

export const SET_MEASUREMENT_LABEL_REQUEST='SET_MEASUREMENT_LABEL_REQUEST'