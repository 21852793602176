import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import image from "../../../rels_assets/images/blog/b-11.jpg";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import _ from 'lodash';
import Slider from 'react-slick';



const ProjectListBlock = (props) => {
    const dispatch = useDispatch();
    const currency = props.currency;
    const [projects, setProjects] = useState([]);

    const settings = {
        // dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        pauseOnHover: true,
        centerMode: true,
    };
    /* const popover = (
        <Popover id="popover-basic" >
            <Popover.Title as="h2">Units</Popover.Title>
            <Popover.Content className="projectList-popover-size p-0">
                <div key={`projectList-block`} className="widget-boxed p-0">
                    {(projects && projects.building_units && projects.building_units.length > 0) ?
                        <Slider {...settings}>
                            {
                                projects.building_units.map((i, units) => {
                                    return (
                                        <div key={"building-Image" + units} className="col-lg-12 col-md-12 px-0 my-2">
                                            <Card className="shadow-lg dark mx-2" >
                                                <Card.Header>{i.unit_config} | {i.specific_property}</Card.Header>
                                                <Card.Body className="py-2">
                                                    <div><span className="font-weight-bold">{i.floor} Floor</span></div>
                                                    <small> Super Built-Up Area </small>
                                                    <div>
                                                        {i.total_super_builtup_area}
                                                    </div>
                                                    <footer className="font-weight-bolder">
                                                        Price {currency.symbol} {i.final_super_built_up_area_rate}
                                                    </footer>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                        : <div className="col-lg-12 col-md-12 px-0 my-2 p-3">No Units Available !</div>
                    }
                </div>
            </Popover.Content>
        </Popover>
    ); */

    useEffect(() => {
        setProjects(props.property);
    }, [props]);


    return (
        <>
            <div
                className={`agents-grid ${props.columnClass ? props.columnClass : ''}`}

                data-aos="fade-up"
                data-aos-delay="150"
            >
                <div className="landscapes">
                    <div className="project-single">
                        <div className="project-inner project-head">
                            <div className="homes">
                                {/* <!-- homes img --> */}
                                <a

                                    className="homes-img"
                                    aria-label="discover-properties"
                                >
                                    <div className="homes-tag button alt featured project-homes-tag">{projects.building_status}</div>
                                    {/* <div
                                        className={`homes-tag button alt sale`}
                                    >
                                        {projects.build_type}
                                    </div> */}

                                    <img loading="lazy"   src={
                                        projects?.building_images &&
                                            projects.building_images.length > 0
                                            ? projects.building_images[0].image
                                            : image} onError={(e) => (
                                                e.target.onerror = null,
                                                e.target.src = image
                                            )
                                            }
                                        alt="home-1"
                                        className="img-responsive" />
                                </a>
                            </div>
                            {
                                projects.map_link &&
                                <div className="button-effect pr-2">
                                    <a
                                        href={projects.map_link} target="_blank"
                                        className="btn"
                                        aria-label={`project-${projects.map_link}`}
                                    >
                                        <i className="fa fa-link"></i>
                                    </a>
                                    {/* <OverlayTrigger trigger='click' placement="auto-end" overlay={popover} rootClose>
                                        <Button variant="secondary"><i className="fa fa-link"></i></Button>
                                    </OverlayTrigger> */}
                                </div>
                            }

                        </div>
                        {/* <!-- homes content --> */}
                        <div className="homes-content">
                            {/* <!-- homes address --> */}
                            <h3>
                                <a aria-label={projects.name}>{projects.name}</a>
                            </h3>
                            <p className="text-truncate" title={_.join(_.map(projects.partners), " & ")}>By {_.join(_.map(projects.partners), " & ")} </p>
                            <div className="homes-address mb-3">
                                <h3 className="text-truncate" title={projects.building_area}>
                                    <a aria-label={projects.building_area}>
                                        <i className="fa fa-map-marker"></i>
                                        <span> {projects.building_area}</span>
                                    </a></h3>
                            </div>
                            {/* <!-- homes List --> */}
                            {/* <ul className="homes-list clearfix pb-3">
                                <li className="the-icons">
                                    <i
                                        className="flaticon-bed mr-2"
                                        aria-hidden="true"
                                    ></i>
                                    <span className="sale rent">
                                        <u>{projects.building_status}</u>
                                    </span>
                                </li>
                                <li className="the-icons">
                                                <i className="flaticon-bathtub mr-2" aria-hidden="true"></i>
                                                <span>3 Bathrooms</span>
                                            </li>
                                <li className="the-icons">
                                    <i
                                        className="flaticon-square mr-2"
                                        aria-hidden="true"
                                    ></i>
                                    <span>{projects.built_up_area}</span>
                                </li>
                                <li className="the-icons">
                                                <i className="flaticon-car mr-2" aria-hidden="true"></i>
                                                <span>2 Garages</span>
                                            </li>
                            </ul> */}

                            {/*  <div className="price-properties footer pt-3 pb-0">
                                <h3 className="title mt-3">
                                    <a href="single-property-1.html">$ 350,000</a>
                                </h3>
                                <div className="compare">
                                <a href="#" title="Compare">
                                                    <i className="flaticon-compare"></i>
                                                </a>
                                                <a href="#" title="Share">
                                                    <i className="flaticon-share"></i>
                                                </a>
                                                <a href="#" title="Favorites">
                                                    <i className="flaticon-heart"></i>
                                                </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectListBlock;
