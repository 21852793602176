import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import $, { data } from 'jquery'
import { fetchSearchOptionsRequest } from '../actions'
import { fetchPropertiesByFilterRequest, setSelectedOptionsRequest } from '../../Landing/actions'
import DropDownData from '../dropDownData'
import { Slider } from '@material-ui/core'
import { useHistory, useLocation } from "react-router-dom"
import request from '../../../service/request'
import { getQueryString } from '../../../helper'


const SearchFilters = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [filterData, setFilterData] = useState({});
    const filterOptions = useSelector(store => store.commonPageData.searchOptions);
    const propertyTypeOption = useSelector(store => store.landingPageData.selectedOptions.property_type);
    const SpecificPropertyOption = useSelector(store => store.landingPageData.selectedOptions.specific_property);
    const propertyConfigOption = useSelector(store => store.landingPageData.selectedOptions.property_configuration);
    const propertyForOption = useSelector(store => store.landingPageData.selectedOptions.property_for);
    const countryOption = useSelector(store => store.landingPageData.selectedOptions.country);
    const stateOption = useSelector(store => store.landingPageData.selectedOptions.state);
    const cityOption = useSelector(store => store.landingPageData.selectedOptions.city);
    const hotPropertyOption = useSelector(store => store.landingPageData.selectedOptions.hot_property);
    const areaOption = useSelector(store => store.landingPageData.selectedOptions.area);
    const areaSizeOption = useSelector(store => store.landingPageData.selectedOptions.area_size);
    const measurementOption = useSelector(store => store.landingPageData.selectedOptions.measurement);
    const measurementLable = useSelector(store => store.commonPageData.measurement_lable);

    const allSelectedOption = useSelector(store => store.landingPageData.selectedOptions);
    const shouldClearDropdown = location.pathname !== '/hot-property';

    const [areaSize, setAreaSize] = useState([100, 2000]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [area, setArea] = useState([]);
    const [specificProperty, setSpecificProperty] = useState([]);
    const [propertyConfiguration, setPropertyConfiguration] = useState([])
    const [clearKey, setClearKey] = useState(0);
    const [clearHotProperty, setClearHotProperty] = useState(true);
    const [clearDropdown, setClearDropdown] = useState(false);



    const handleChange = (e, newFirstValue) => {
        e.preventDefault();
        setAreaSize(newFirstValue);
        const optVal = newFirstValue;
        const foptVal = optVal.join(",");
        dispatch(setSelectedOptionsRequest({ 'area_size': foptVal }));
    };

    useEffect(() => {
        loadSearchData();
    }, []);

    useEffect(() => {
        if (typeof filterOptions === 'object' && filterOptions.data && (Object.keys(filterOptions.data)).length > 0) {
            setFilterData(filterOptions.data);
        }
    }, [filterOptions]);

    useEffect(() => {
        /** Load city data reamining.. */
        loadCity(stateOption)
    }, [stateOption])

    useEffect(() => {
        loadAreas(countryOption, stateOption, cityOption);
    }, [stateOption, cityOption, countryOption])

    function loadSearchData() {
        dispatch(fetchSearchOptionsRequest(JSON.stringify([data])));
    }
    function loadCity(stateid) {
        request({
            method: 'post',
            url: '/get-city-by-State',
            data: { 'state_id': stateid }
        }).then((response) => {
            setCity(response.data.data.cities);
        });
    }
    function loadAreas(country_id, state_id, city_id) {
        request({
            method: 'post',
            url: '/get-areas',
            data: { 'country_id': country_id, 'state_id': state_id, 'city_id': city_id }
        }).then((response) => {
            setArea(response.data.data.areas);
        });
    }
    useEffect(() => {
        setState(filterData?.locations);
    }, [filterData]);

    useEffect(() => {
        if (propertyTypeOption) {
            request({
                method: 'post',
                url: '/get-specific-properties',
                data: { 'property_type': propertyTypeOption }
            }).then((response) => {
                setSpecificProperty(response.data.data.specific_property);
            });

            request({
                method: 'post',
                url: '/get-property-config',
                data: { 'property_type': propertyTypeOption }
            }).then((response) => {
                setPropertyConfiguration(response.data.data.property_config);
            })
        } else {
            setSpecificProperty([]);
            setPropertyConfiguration([]);
        }
    }, [propertyTypeOption])

    useEffect(() => {
        let kinjal = (areaSizeOption && areaSizeOption.length > 0) ? (areaSizeOption.split(',')).map((i) => { return parseInt(i) }) : [100, 2000];
        if (kinjal && kinjal.length > 0) {
            setAreaSize(kinjal);
        }
    }, [areaSizeOption]);

    const handleClick = (event) => {
        if (event.type === 'click') {
            let QueryString = "";
            dispatch(setSelectedOptionsRequest(allSelectedOption));
            if (typeof allSelectedOption === 'object' && Object.keys(allSelectedOption).length > 0) {
                QueryString = getQueryString(allSelectedOption);
                // dispatch the properties list by selected filter options..
                dispatch(fetchPropertiesByFilterRequest(JSON.stringify(allSelectedOption)))
            }
            history.push({
                pathname: '/all/property',
                search: `?${QueryString}`
            })
        }
    }

    const handleClearDropdown = () => {
        setClearHotProperty(true);
        setClearKey((prevKey) => prevKey + 1);
        setAreaSize([100, 2000]);

        dispatch(setSelectedOptionsRequest({
            'property_type': '',
            'property_for': '',
            'specific_property': '',
            'property_configuration': '',
            'country': '',
            'state': '',
            'city': '',
            'area': '',
            'measurement': '',
        }));
    };


    return (
        <>
            {/* <!-- Search Form --> */}
            <div className="col-xl-12 px-0 parallax-searchs">
                <div className="banner-search-wrap">
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="tabs_1">
                            <div className="rld-main-search h-auto">
                                <div className="row justify-content-center justify-content-lg-start search-margin align-items-center">
                                    {
                                        (filterData && Object.keys(filterData).length > 0) ?
                                            <>
                                                <DropDownData options={filterData && filterData?.property_types} storeKey={'property_type'} choosedOption={'Property Type'} defaultOption={(propertyTypeOption) ? propertyTypeOption : ''} clearDropdown={clearDropdown} clearKey={clearKey} />
                                                <DropDownData options={filterData.property_for} storeKey='property_for' choosedOption={'Property For'} defaultOption={propertyForOption ? propertyForOption : ''} clearDropdown={clearDropdown} clearKey={clearKey} />
                                                <DropDownData options={specificProperty} storeKey='specific_property' choosedOption={'Specific Property'} defaultOption={(propertyTypeOption && SpecificPropertyOption) ? SpecificPropertyOption : ''} clearDropdown={clearDropdown} clearKey={clearKey} />
                                                <DropDownData options={propertyConfiguration} storeKey='property_configuration' choosedOption={'Property Configuration'} defaultOption={(propertyTypeOption && propertyConfigOption) ? propertyConfigOption : ''} clearDropdown={clearDropdown} clearKey={clearKey} />

                                                <DropDownData options={[{ 'id': 101, 'name': 'India' }]} storeKey='country' choosedOption={'Country'} defaultOption={countryOption ? countryOption : ''} /> {/* if clear is require then add attribute clearKey={clearKey} clearDropdown={clearDropdown} */}

                                                <DropDownData options={state} storeKey='state' choosedOption={'State'} defaultOption={stateOption ? stateOption : ''} clearDropdown={clearDropdown} clearKey={clearKey} />

                                                <DropDownData options={city} storeKey='city' choosedOption={'City'} defaultOption={cityOption ? cityOption : ''} clearDropdown={clearDropdown} clearKey={clearKey} />

                                                <DropDownData options={[{ 'id': 'true', 'name': 'Yes' }, { 'id': 'false', 'name': 'No' }]} storeKey='hot_property' choosedOption={'Hot Property'} defaultOption={hotPropertyOption ? hotPropertyOption : ''}
                                                    clearDropdown={!clearHotProperty} clearKey={clearKey} />

                                            </>
                                            : ''
                                    }

                                    <DropDownData options={area} className="col-lg-4 col-md-5" storeKey='area' choosedOption={'Area'} defaultOption={areaOption ? areaOption : ''} clearDropdown={clearDropdown} clearKey={clearKey} />
                                    <DropDownData className="col-lg-4 col-md-5" options={filterData?.measurements} storeKey='measurement' choosedOption={'Area Size Measurement'} defaultOption={measurementOption ? measurementOption : ''} clearDropdown={clearDropdown} clearKey={clearKey} />
                                    <div className='rld-single-select ml-22  col-lg-4 col-md-6'>
                                        <label>&nbsp;</label>
                                        <Slider
                                            value={areaSize}
                                            className="area-range range-slider "
                                            onChange={handleChange}
                                            color="secondary"
                                            disabled={!measurementOption}
                                            min={100}
                                            max={2000}
                                        />
                                        <div className='text-left float-left'>{areaSize[0]} {measurementLable ? measurementLable : ""}</div>{/* Get Value From Store For Mesurement */}
                                        <div className='text-right float-right'>{areaSize[1]} {measurementLable ? measurementLable : ""}</div>{/* Get Value From Store For Mesurement */}
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="rld-single-select ml-22  col-lg-12 col-md-12 mt-3 d-flex justify-content-center">
                                        <button className="btn btn-yellow customButton" onClick={handleClick} >Search Now</button>
                                        <button className="btn btn-yellow customClearButton ml-5" onClick={handleClearDropdown}>Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--/ End Search Form --> */}
        </>
    )
}

export default SearchFilters
