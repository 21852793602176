import * as routesConstants from '../config/routesPatch';
import {lazy} from 'react'

const resources = [
    {
        route: routesConstants.HOME_PATH,
        component: lazy(() => import('../components/Landing/Landing'))
    },
    {   route: routesConstants.PROPERTY_LIST,
        component: lazy(() => import('../components/properties-list/propertiesList')),        
    },
    {   route: routesConstants.PROPERTY_DETAILS,
        component: lazy(() => import('../components/property-detail/propertyDetail'))
    }
    /* {
        route: routesConstants.ABOUT_US_PATH,
        component: lazy(() => import('../components/about-us'))
    },
    {
        route: routesConstants.CONTACT_US_PATH,
        component: lazy(() => import('../components/contact-us'))
    },
    {
        route: routesConstants.FAQ_PATH,
        component: lazy(() => import('../components/faq'))
    },
    {   route: routesConstants.PRIVACY_POLICY,
        component: lazy(() => import('../components/privacy-policy'))
    },
    {   route: routesConstants.TERMS_CONDITION_PATH,
        component: lazy(() => import('../components/terms-condition'))
    },
    {   route: routesConstants.PRODUCT_DETAILS,
        component: lazy(() => import('../components/product-details'))
    },
    {   route: routesConstants.MY_CART,
        component: lazy(() => import('../components/my-cart'))
    },
    {   route: routesConstants.CHECK_OUT,
        component: lazy(() => import('../components/checkout'))
    },
    {   route: routesConstants.MY_PROFILE,
        component: lazy(() => import('../components/my-profile'))
    },
    {   route: routesConstants.MY_ORDER,
        // component: lazy(() => import('../components/my-order'))
        component: lazycomp
    },
    {   route: routesConstants.SAVED_ADDRESS,
        component: lazycomp
        // component: SavedAddress
        // component: lazy(() => import('../components/savedAddress'))
    },
    {   route: routesConstants.MY_ACCOUNT,
        component: lazycomp
    },
    {   route: routesConstants.MY_WISHLIST,
        component: lazycomp
    },
    {   route: routesConstants.MY_INQUIRY,
        component: lazycomp
    },
    {
        route: routesConstants.ORDER_DETAILS,
        component: orderItems
    } */
]

export default resources;
