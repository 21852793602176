export function createProductSlug(item)
{
    const Text = `{${item.productName}, ${item.productID}}`
    return Text
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
}

export function createSupplierSlug(item)
{
    const Text = `{${item.name}, ${item.id}}`
    return Text
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
}

export function createCategorySlug(item)
{
    const Text = `{${item.categoryName}, ${item.categoryID}}`
    return Text
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
}

export function createSlugFromSearch(item)
{
    const Text = `{${item.Name}, ${item.ID}}`
    return Text
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
}

export function convertToSlug(Text)
{
    return Text
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
}
export function getIdFromTheSlug(Text){
    return Text.split('-').pop();
}

export function getQueryString(obj){
    const QueryString =  Object.keys(obj).map((item)=>{       
        return `${item}=${obj[item]}`
      })
    return QueryString.join("&");
}
