import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import CustomNav from './CustomNav';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import RootReducer from './reducer/index'
import rootSaga from './sagas/index'
import initialState from './store/initialState'
import createSagaMiddleware from 'redux-saga'
import SystemMessage from './components/notifications/system/components/SystemMessage';
import { HelmetProvider } from 'react-helmet-async';

// const sagaMiddleware = createSagaMiddleware();
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(RootReducer,initialState,/* preloadedState, */ composeEnhancers(applyMiddleware(sagaMiddleware)))

const sagaMiddleware = createSagaMiddleware();
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true }) || compose;
const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true }) : null) || compose;
const store = createStore(RootReducer, initialState,/* preloadedState, */ composeEnhancers(applyMiddleware(sagaMiddleware)))
sagaMiddleware.run(rootSaga)

// sagaMiddleware.run(rootSaga)
ReactDOM.render(
  <>
    <HelmetProvider>
      <React.Fragment>
        <Provider store={store}>
          <App />
        </Provider>
      </React.Fragment>
    </HelmetProvider>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
