export const VALIDATE_LOGIN_REQUEST = "VALIDATE_LOGIN_REQUEST"
export const VALIDATE_LOGIN_FALIED = "VALIDATE_LOGIN_FALIED"
export const VALIDATE_LOGIN_SUCCESS = "VALIDATE_LOGIN_SUCCESS"

export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST"
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS"
export const USER_SIGNUP_FAILURE = "USER_SIGNUP_FAILURE"

export const OTP_VERIFICATION_REQUEST = "OTP_VERIFICATION_REQUEST"
export const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS"
export const OTP_VERIFICATION_FAILURE = "OTP_VERIFICATION_FAILURE"

export const FORGOT_PSWD_OTP_REQUEST = "FORGOT_PSWD_OTP_REQUEST"
export const FORGOT_PSWD_OTP_SUCCESS = "FORGOT_PSWD_OTP_SUCCESS"
export const FORGOT_PSWD_OTP_FAILURE = "FORGOT_PSWD_OTP_FAILURE"

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE"

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE"

export const TOGGLE_LOGIN_MODAL = 'TOGGLE_LOGIN_MODAL'
