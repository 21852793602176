import React, { useEffect, useState } from 'react'
import image from "../../rels_assets/images/blog/b-11.jpg";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import NewPagination from '../../NewPagination';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ProjectListBlock from '../common/Property/projectListBlock';
import { fetchAllProjectsRequest } from '../Landing/actions';
import $, { data } from 'jquery';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import request from '../../service/request';

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

function SkeletonCustom(props) {
    return (
        <>
            {/* <div className="servicesbox-content arrowtop_right">
                <div className="category-carousel">
                    <div className="slider_itemBox">
                        <a className={props.parentClassName}><Skeleton className={props.classes} width={props.width} height={props.height} inline={props.inline} count={props.count} style={{ height: "300px", margin: "10px" }} /></a>
                    </div>
                </div>
            </div> */}

            <div className={`  ${props.parentClassName}`} >
                <Skeleton className={props.classes} width={props.width} height={props.height} inline={props.inline} count={props.count} style={{ height: "450px" }} />
            </div>

        </>
    );
}

const ProjectsList = (props) => {
    const newselectedOptions = useSelector(store => store.landingPageData.selectedOptions > 0 ? store.landingPageData.selectedOptions : store.landingPageData.selectedOptions)

    const loading = useSelector(store => store.landingPageData.isPropertyListLoading == false ? store.landingPageData.isPropertyListLoading : store.landingPageData.isPropertyListLoading)

    const dispatch = useDispatch();
    const history = useHistory();
    const [viewName, setViewName] = useState('gridView');
    const [totalSearch, setTotalSearch] = useState('');

    const [toggleTopSelling, setToggleTopSelling] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const [pagingMeta, setPagingMeta] = useState(false);
    const [lastPage, setLastPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [choosedSortBy, setChoosedSortBy] = useState('Top Selling');
    const [popularprojects, setProject] = useState([]);
    const [menu, setMenu] = useState([]);
    const projects = useSelector(store => store.landingPageData?.allProjects?.length > 0 ? store.landingPageData.allProjects : store.landingPageData.allProjects)
    const pageName = history.location.pathname.split('/').map((i) => _.startCase(_.camelCase(i))).join(' / ');

    useEffect(() => {
        loadData();
        scrollToTop();
    }, []);

    useEffect(() => {
        /** setProject.! */
        if (projects && typeof projects.data === 'object' && Object.keys(projects.data).length > 0) {
            setProject(projects.data);
            setTotalSearch(projects?.meta?.total);
            setLastPage(projects?.meta?.last_page);
            setPagingMeta(projects?.meta);
        }
    }, [projects]);

    useEffect(() => {
        let newParam = { ...data, "page": currentPage }
        dispatch(fetchAllProjectsRequest(JSON.stringify(newParam)))
    }, [currentPage]);

    useEffect(() => {
        request({
            method: 'get',
            url: '/get-menu',
            headers: { "Content-Type": "application/json", "Accept": "application/json" },
        })
            .then((response) => {
                setMenu(response?.data);
            })
            .catch(({ data }) => ({ error: data }));
    }, []);

    /** Custom function */
    function loadData() {
        dispatch(fetchAllProjectsRequest(JSON.stringify([data])))
    }

    return (
        <>
            <Helmet>
                <title>{`${menu.data?.site_setting?.site_name} - ${pageName.replace('/', '')}`}</title>
            </Helmet>
            {/* <!-- START SECTION PROPERTIES LISTING GRID VIEW--> */}
            <section className={(viewName == 'gridView' ? "properties-list featured portfolio blog" : "d-none")}>
                <div className="container">
                    <section className="headings-2 pt-0 pb-0">
                        <div className="pro-wrapper">
                            <div className="detail-wrapper-body">
                                <div className="listing-title-bar">
                                    <div className="text-heading text-left">
                                        {/* <p><a href="index.html">Home </a> &nbsp;/&nbsp; <span>Projects Listings</span></p> */}
                                    </div>
                                    <h3>{pageName.replace('/', '')}</h3>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <SearchFilters /> */}

                    <section className="headings-2 pt-0">
                        <div className="pro-wrapper">
                            <div className="detail-wrapper-body">
                                <div className="listing-title-bar">
                                    <div className="text-heading text-left">
                                        <p className="font-weight-bold mb-0 mt-3">{totalSearch} Search results</p>
                                    </div>
                                </div>
                            </div>
                            <div className="cod-pad single detail-wrapper mr-2 mt-0 d-flex justify-content-md-end align-items-center">
                                {/* <div className='input-group border rounded input-group-lg w-auto mr-4'>
                                    <label className="input-group-text bg-transparent border-0 text-uppercase letter-spacing-093 pr-1 pl-3" for="inputGroupSelect01">
                                        <i className="fas fa-align-left fs-16 pr-2"></i>Sortby:</label>
                                    <select className="form-control border-0 bg-transparent shadow-none p-0 selectpicker sortby" data-style="bg-transparent border-0 font-weight-600 btn-lg pl-0 pr-3" id="inputGroupSelect01" name="sortby" style={{ display: "none" }}>
                                        <option selected="">Top Selling</option>
                                        <option value="1">Most Viewed</option>
                                        <option value="2">Price(low to high)</option>
                                        <option value="3">Price(high to low)</option>
                                    </select>
                                    <div className={`nice-select form-control border-0 bg-transparent shadow-none p-0 selectpicker sortby ${toggleTopSelling ? 'open' : ''}`} onClick={() => { setToggleTopSelling(!toggleTopSelling) }} onBlur={() => { setToggleTopSelling(false) }} tabindex="0">
                                        <span className="current">{choosedSortBy}</span>
                                        <ul className="list">
                                            <li data-value="Top Selling" onClick={() => { setChoosedSortBy('Top Selling') }} className="option selected focus">Top Selling</li>
                                            <li data-value="1" onClick={() => { setChoosedSortBy('Most Viewed') }} className="option">Most Viewed</li>
                                            <li data-value="2" onClick={() => { setChoosedSortBy('Price(low to high)') }} className="option">Price(low to high)</li>
                                            <li data-value="3" onClick={() => { setChoosedSortBy('Price(high to low)') }} className="option">Price(high to low)</li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="sorting-options">
                                    <a onClick={(e) => { e.preventDefault(); setViewName("listView") }} href="#" className="change-view-btn lde"><i className="fa fa-th-list"></i></a>
                                    <a onClick={(e) => { e.preventDefault(); setViewName("gridView") }} href="#" className="change-view-btn active-view-btn"><i className="fa fa-th-large"></i></a>
                                </div> */}
                            </div>
                        </div>
                    </section>
                    {/* property grid block start */}
                    <div className='row featured portfolio-items'>
                        {
                            isLoading == true ?
                                <>
                                    <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                                        <SkeletonCustom parentClassName="productListSketelonParent" inline={true} count={1} />
                                    </div>
                                    <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                                        <SkeletonCustom parentClassName="productListSketelonParent" inline={true} count={1} />
                                    </div>
                                    <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                                        <SkeletonCustom parentClassName="productListSketelonParent" inline={true} count={1} />
                                    </div>
                                    <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                                        <SkeletonCustom parentClassName="productListSketelonParent" inline={true} count={1} />
                                    </div>
                                    <div className="col-lg-4 col-xl-4 col-md-4 col-sm-4 col-12">
                                        <SkeletonCustom parentClassName="productListSketelonParent" inline={true} count={1} />
                                    </div>
                                </>
                                :
                                popularprojects && popularprojects.length > 0 && popularprojects.map((item, index) => {
                                    return (
                                        // <><PropertyListBlock property={item} columnClass={`col-lg-4 col-md-6 col-xs-12`} /></>
                                        <><React.Fragment key={`popular-projects-${index}`}><ProjectListBlock property={item} currency={projects?.currency} columnClass={`col-lg-4 col-md-6 col-xs-12`} /></React.Fragment></>
                                    )
                                })
                        }
                    </div>
                    {/* property grid block end */}
                    {/* pagination start */}
                    {
                        pagingMeta ?
                            <NewPagination
                                className="pagination-bar"
                                currentPage={currentPage}
                                totalCount={pagingMeta.last_page}
                                pageSize={1}
                                // onPageChange={setCurrentPage}
                                onPageChange={page => setCurrentPage(page)}
                            /> : ''
                    }
                </div>
            </section>
            {/* <!-- END SECTION PROPERTIES LISTING GRID VIEW-->      */}
        </>
    )
}

export default ProjectsList

